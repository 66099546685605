import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchSeoSetting,
  updateSeoSetting,
} from "services/website-setting/seoSettingService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useSeoSetting = () => {
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const queryClient = useQueryClient();

  const [metaKeyword, setMetaKeyword] = useState([]);
  const { data: seoDetail, isLoading } = useQuery(
    QueryKeys.SEO_SETTING,
    async () => {
      const response = await fetchSeoSetting();
      const data = response.data.data;

      const splitKeywords = data.metaKeyword
        ? data.metaKeyword.split(" ").map((keyword, index) => ({
            name: keyword.trim(),
            id: index + 1,
          }))
        : [];
      setMetaKeyword(splitKeywords);

      return data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation(updateSeoSetting, {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries(QueryKeys.SEO_SETTING);
    },
    onError: (error) => handleError(error, ERROR_MESSAGE),
  });

  const keyPress = (e, setFieldValue) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      const newKeywords = [
        ...metaKeyword,
        {
          name: e.target.value,
          id:
            metaKeyword.length === 0
              ? 1
              : metaKeyword[metaKeyword.length - 1].id + 1,
        },
      ];
      setMetaKeyword(newKeywords);
      setFieldValue("metaKeyword", newKeywords);
      e.target.value = "";
    }
  };

  const handleSubmit = (values) => {
    let formattedKeywords = "";
    if (Array.isArray(values.metaKeyword)) {
      formattedKeywords = values.metaKeyword
        .map((keyword) => keyword.name)
        .join(" ");
    } else {
      formattedKeywords = values.metaKeyword;
    }

    const formattedValues = { ...values, metaKeyword: formattedKeywords };

    mutation.mutate(formattedValues);
  };

  return {
    seoDetail,
    metaKeyword,
    setMetaKeyword,
    isLoading,
    keyPress,
    handleSubmit,
    isSubmitting: mutation.isLoading,
  };
};
