import { Box } from "@chakra-ui/react";
import React from "react";

const IframeView = ({ view, iframeKey, webSiteUrl }) => {
  const iframeStyles = {
    desktop: {
      width: "1200px",
      height: "675px",
      iframeWidth: "1920px",
      iframeHeight: "1080px",
      scale: 0.625,
    },
    mobile: {
      width: "320px",
      height: "692px",
      iframeWidth: "430px",
      iframeHeight: "932px",
      scale: 0.74,
    },
    tablet: {
      width: "600px",
      height: "800px",
      iframeWidth: "768px",
      iframeHeight: "1024px",
      scale: 0.78,
    },
  };

  const { width, height, iframeWidth, iframeHeight, scale } =
    iframeStyles[view];

  return (
    <Box
      style={{
        width,
        height,
        borderRadius: "10px",
        border: "1px solid #373F48",
        transformOrigin: "top left",
        overflow: "hidden",
      }}
    >
      <iframe
        key={iframeKey}
        src={webSiteUrl}
        width={iframeWidth}
        height={iframeHeight}
        style={{
          border: "none",
          transform: `scale(${scale})`,
          transformOrigin: "top left",
        }}
        title={`${view.charAt(0).toUpperCase() + view.slice(1)} Website View`}
      />
    </Box>
  );
};

export default IframeView;
