import {
  Flex,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import AnimatedNumber from "components/AnimatedNumber/AnimatedNumber";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import IconBox from "components/Icons/IconBox";
import React from "react";

const ReportsCard = ({
  icon,
  title,
  number,
  isInteger,
  description,
  mode = null,
  isLoading = true,
}) => {
  const cleanNumber = parseFloat(number?.replace(/,/g, ""));

  let mainColor;

  if (mode === "Deposit") {
    mainColor = "rgba(49, 170, 147, 1)";
  } else if (mode === "Withdraw") {
    mainColor = "rgba(187, 52, 76, 1)";
  } else {
    mainColor = "rgba(55, 63, 72, 1)";
  }

  return (
    <Card border={`1px solid ${mainColor}`} bgColor="gray.900">
      <CardBody h="100%">
        <Flex direction="column" justify="space-between" w="100%" h="100%">
          <Flex justify="space-between" align="center" w="100%">
            <Flex direction="column" align="flex-start">
              <StatGroup>
                <Stat>
                  <IconBox
                    w={{ base: "30px", md: "50px" }}
                    h={{ base: "30px", md: "50px" }}
                    mb="16px"
                    backgroundColor={mainColor}
                  >
                    {icon}
                  </IconBox>
                  <StatNumber>
                    {isLoading && !cleanNumber ? (
                      <span className="pulse"></span>
                    ) : (
                      <AnimatedNumber
                        n={cleanNumber}
                        dp={isInteger ? 0 : 2}
                        mode={mode}
                        fontSize="32px"
                      />
                    )}
                  </StatNumber>
                  <StatLabel>{title}</StatLabel>
                  <StatHelpText
                    color="gray.500"
                    fontSize={{ base: "xs", md: "sm" }}
                  >
                    {description}
                  </StatHelpText>
                </Stat>
              </StatGroup>
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ReportsCard;
