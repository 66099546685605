import { Box, Button, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { CreateIcon } from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import { Loader } from "components/Loader/Loader";
import { useBankSetting } from "hooks/business/finance-setting/useBankSetting";
import React, { useState } from "react";

import BankCard from "./components/BankCard";
import DeleteBankModal from "./components/DeleteBankModal";
import ManageBankModal from "./components/ManageBankModal";

const BanksSetting = ({ pageName }) => {
  const { bankData, isLoading, handleSubmit, handleDelete, isDeleting } =
    useBankSetting();

  const [modalState, setModalState] = useState({
    manageBankModalOpen: false,
    deleteBankModalOpen: false,
  });
  const [selectedBank, setSelectedBank] = useState(null);

  const handleModalOpen = (modal, user = null) => {
    setSelectedBank(user);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedBank(null);
  };

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            justifyContent="space-between"
            direction={{ base: "column", md: "row" }}
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              color="#fff"
              pb={{ base: "12px", md: "24px" }}
            >
              ตั้งค่า ธนาคารฝาก-ถอน
            </Text>
            <Button
              w="150px"
              background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              variant="solid"
              borderRadius="33px"
              fontSize="14px"
              fontWeight="normal"
              onClick={() => handleModalOpen("manageBankModal")}
            >
              <Icon as={CreateIcon} mr="5px" />
              เพิ่มธนาคาร
            </Button>
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          {isLoading ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              height="400px"
              w="100%"
            >
              <Loader />
            </Flex>
          ) : (
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing={6}
              w="100%"
            >
              {bankData
                .slice()
                .sort((a, b) => a.id - b.id)
                .map((bank) => (
                  <Box key={bank.id}>
                    <BankCard
                      bank={bank}
                      onEdit={() => handleModalOpen("manageBankModal", bank)}
                      onDelete={() => handleModalOpen("deleteBankModal", bank)}
                    />
                  </Box>
                ))}
            </SimpleGrid>
          )}
        </Animated>
      </Card>
      <ManageBankModal
        isOpen={modalState.manageBankModalOpen}
        onClose={() => handleModalClose("manageBankModal")}
        bank={selectedBank}
        handleSubmit={handleSubmit}
      />
      <DeleteBankModal
        isOpen={modalState.deleteBankModalOpen}
        onClose={() => handleModalClose("deleteBankModal")}
        bank={selectedBank}
        handleSubmit={handleDelete}
        isSubmitting={isDeleting}
      />
    </Flex>
  );
};

export default BanksSetting;
