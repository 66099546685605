import { useState } from "react";
import { fetchJackpotHistory } from "services/system-setting/jackpotSettingService";
import { mapJsonToTableData } from "utils/CommonUtils";
import { jackpotReportColumnsData } from "variables/columns/website-report/jackpotReportColumnsData";

export const useJackpotReport = () => {
  const [downloadableData, setDownloadableData] = useState([]);

  const fetchData = async (pageIndex, pageSize, search) => {
    const response = await fetchJackpotHistory(pageIndex, pageSize, search);
    const formattedRecords = mapJsonToTableData(
      response.results,
      jackpotReportColumnsData,
    );

    setDownloadableData(formattedRecords);
    return response;
  };

  return {
    fetchData,
    downloadableData,
  };
};
