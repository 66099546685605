import { IconButton } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/Icons";
import { formatDate } from "utils/DateformatUtils";

export const roleSettingColumnsData = (history) => {
  return [
    {
      Header: "ตำแหน่ง",
      accessor: "roleName",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "สร้างโดย",
      accessor: "audit.createdBy",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "วันที่สร้าง",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "อัพเดทล่าสุดเมื่อ",
      accessor: "updatedDate",
      Cell: ({ value }) => formatDate(value),
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "",
      accessor: "edit",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ row }) =>
        row.original.id === 1 ? (
          <IconButton
            border="none"
            aria-label="edit"
            size="sm"
            background="none"
            _hover={{ background: "none" }}
            _active={{ background: "none" }}
          />
        ) : (
          <IconButton
            variant="solid"
            aria-label="edit"
            size="sm"
            borderRadius="28px"
            w="40px"
            h="30px"
            bg="green.500"
            icon={<EditIcon h="18px" w="18px" color="#fff" />}
            onClick={() =>
              history.push(`/admin/system-setting/role/edit/${row.original.id}`)
            }
          >
            แก้ไข
          </IconButton>
        ),
      disableSortBy: true,
    },
  ];
};
