import { Badge, Flex } from "@chakra-ui/react";

export const memberLoginHistoryColumnsData = [
  {
    Header: "สถานะ",
    accessor: "active",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ cell }) => (
      <Flex align="center">
        <Badge
          bg={cell.value === true ? "green.500" : "red.500"}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cell.value === true ? "ONLINE" : "OFFLINE"}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ยูสเซอร์",
    accessor: "username",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "เข้าสู่ระบบล่าสุด",
    accessor: "lastlogin",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "IP",
    accessor: "ip",
    textAlign: "left",
    justifyContent: "flex-start",
  },
];
