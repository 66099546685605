import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { DeleteIcon } from "components/Icons/Icons";
import ImageUploader from "components/ImageUploader/ImageUploader";
import PageHeader from "components/Layout/PageHeader";
import { FieldArray, Form, Formik } from "formik";
import { useSpinnerSetting } from "hooks/business/system-setting/useSpinnerSetting";
import React from "react";
import { Element } from "react-scroll";

const SpinnerSetting = ({ pageName }) => {
  const { spinnerSettingData, handleSubmit, isSaving } = useSpinnerSetting();

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card>
        {spinnerSettingData && (
          <Formik initialValues={spinnerSettingData} onSubmit={handleSubmit}>
            {({ values, setFieldValue }) => (
              <Form direction="column">
                <Stack direction="column" spacing="24px" w="100%">
                  <Card
                    w={{ sm: "100%" }}
                    alignSelf="flex-end"
                    justifySelf="flex-end"
                    boxShadow="none"
                    p={0}
                  >
                    <CardHeader>
                      <Text fontSize="18px" fontWeight="bold" color="#fff">
                        จัดการตั้งค่าวงล้อ
                      </Text>
                    </CardHeader>
                    <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
                      <Element id="info" name="info">
                        <CardBody>
                          <Grid
                            templateColumns={{
                              base: "1fr",
                              sm: "repeat(2, 1fr)",
                              md: "repeat(2, 1fr)",
                            }}
                            gap="24px"
                            w="100%"
                          >
                            <GridItem colSpan={2}>
                              <Flex>
                                <Switch
                                  isChecked={values.active}
                                  onChange={(e) =>
                                    setFieldValue("active", e.target.checked)
                                  }
                                />
                                <FormLabel ml={6}>เปิด/ปิด วงล้อ</FormLabel>
                              </Flex>
                            </GridItem>
                            <GridItem colSpan={{ base: 2, md: 1 }}>
                              <FormControl>
                                <FormLabel>ยอดฝากต่อ 1 สิทธิ์</FormLabel>
                                <NumberInput
                                  value={values.depositPerSpin}
                                  onChange={(valueString) =>
                                    setFieldValue("depositPerSpin", valueString)
                                  }
                                >
                                  <NumberInputField />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </FormControl>
                            </GridItem>
                            <GridItem display={{ base: "none", md: "grid" }} />
                            <GridItem colSpan={{ base: 2, md: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  รูปกลางวงล้อ{" "}
                                  <Box
                                    as="span"
                                    color="green.500"
                                    fontWeight="normal"
                                  >
                                    (ขนาด 320px x 320px)
                                  </Box>
                                </FormLabel>
                                <Box
                                  position="relative"
                                  display="flex"
                                  alignItems="center"
                                  borderRadius="4px"
                                  minH="40px"
                                  cursor="pointer"
                                >
                                  <ImageUploader
                                    initialImage={values.imgCenter}
                                    onUpload={(uri) =>
                                      setFieldValue("imgCenter", uri)
                                    }
                                  />
                                </Box>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 2, md: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  รูปวงล้อ{" "}
                                  <Box
                                    as="span"
                                    color="green.500"
                                    fontWeight="normal"
                                  >
                                    (ขนาด 800px x 800px)
                                  </Box>
                                </FormLabel>
                                <Box
                                  position="relative"
                                  display="flex"
                                  alignItems="center"
                                  borderRadius="4px"
                                  minH="40px"
                                  cursor="pointer"
                                >
                                  <ImageUploader
                                    initialImage={values.imgSpinWheel}
                                    onUpload={(uri) =>
                                      setFieldValue("imgSpinWheel", uri)
                                    }
                                  />
                                </Box>
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Element>
                    </Animated>
                  </Card>
                  <FieldArray name="items">
                    {({ push, remove }) => (
                      <>
                        <Flex justify="space-between">
                          <Text fontSize="16px" fontWeight="bold" color="#fff">
                            เพิ่มเงื่อนไข
                          </Text>
                          <Button
                            variant="outline"
                            borderColor={"green.500"}
                            color={"#fff"}
                            bg={"#1E252C"}
                            size="md"
                            fontWeight="normal"
                            fontSize="14px"
                            borderRadius="33px"
                            onClick={() =>
                              push({
                                percent: "",
                                imageUrl: "",
                                name: "",
                                description: "",
                                type: "credit",
                                rewardAmount: "",
                                turnAmount: "",
                              })
                            }
                          >
                            เพิ่มเงื่อนไข
                          </Button>
                        </Flex>
                        <Animated
                          animation="fadeInUp"
                          duration="0.8s"
                          delay="0.1s"
                        >
                          <Card
                            w={{ sm: "100%" }}
                            alignSelf="flex-end"
                            justifySelf="flex-end"
                            p={0}
                          >
                            <Element id="info" name="info">
                              <CardBody>
                                <Grid
                                  templateColumns={{
                                    base: "repeat(5, 1fr) auto",
                                    md: "repeat(5, 1fr) auto",
                                  }}
                                  gap="10px"
                                  w="100%"
                                >
                                  <GridItem colSpan={{ base: 6, md: 6 }}>
                                    <Box
                                      borderTop="1px solid #373F48"
                                      borderBottom="1px solid #373F48"
                                      py="10px"
                                    >
                                      <Grid
                                        templateColumns={{
                                          base: "repeat(5, 1fr) auto",
                                          md: "repeat(5, 1fr) auto",
                                        }}
                                        pr={8}
                                      >
                                        <GridItem
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Text>ชื่อรางวัล</Text>
                                        </GridItem>
                                        <GridItem
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Text>เปอร์เซนต์</Text>
                                        </GridItem>
                                        <GridItem
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Text>ประเภทของรางวัล</Text>
                                        </GridItem>
                                        <GridItem
                                          display="flex"
                                          alignItems="flex-start"
                                          flexDir="column"
                                        >
                                          <Text>มูลค่าของรางวัล</Text>
                                          <Text
                                            color="green.500"
                                            fontWeight="normal"
                                            fontSize="12px"
                                          >
                                            (ใส่ 0 หากเป็นไอเท็ม)
                                          </Text>
                                        </GridItem>
                                        <GridItem
                                          display="flex"
                                          alignItems="flex-start"
                                          flexDir="column"
                                        >
                                          <Text>เทิร์นโอเวอร์</Text>
                                          <Text
                                            color="green.500"
                                            fontWeight="normal"
                                            fontSize="12px"
                                          >
                                            (ใส่ 0 หากเป็นไอเท็ม)
                                          </Text>
                                        </GridItem>
                                      </Grid>
                                    </Box>
                                  </GridItem>
                                  {values.items.map((item, index) => (
                                    <React.Fragment key={index}>
                                      <GridItem colSpan={1}>
                                        <FormControl>
                                          <Input
                                            value={item.name}
                                            onChange={(e) =>
                                              setFieldValue(
                                                `items.${index}.name`,
                                                e.target.value,
                                              )
                                            }
                                            placeholder="ชื่อรางวัล"
                                            fontSize="sm"
                                          />
                                        </FormControl>
                                      </GridItem>
                                      <GridItem colSpan={1}>
                                        <FormControl>
                                          <NumberInput
                                            value={item.percent}
                                            onChange={(valueString) =>
                                              setFieldValue(
                                                `items.${index}.percent`,
                                                valueString,
                                              )
                                            }
                                          >
                                            <NumberInputField
                                              name={`items.${index}.percent`}
                                              placeholder="10"
                                              fontSize="sm"
                                            />
                                            <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                            </NumberInputStepper>
                                          </NumberInput>
                                        </FormControl>
                                      </GridItem>
                                      <GridItem colSpan={1}>
                                        <FormControl>
                                          <Select
                                            value={item.type}
                                            onChange={(e) =>
                                              setFieldValue(
                                                `items.${index}.type`,
                                                e.target.value,
                                              )
                                            }
                                            fontSize="sm"
                                            borderRadius="33px"
                                          >
                                            <option value="credit">
                                              เครดิต
                                            </option>
                                            <option value="item">ไอเท็ม</option>
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                      <GridItem colSpan={1}>
                                        <FormControl>
                                          <NumberInput
                                            value={item.rewardAmount}
                                            onChange={(valueString) =>
                                              setFieldValue(
                                                `items.${index}.rewardAmount`,
                                                valueString,
                                              )
                                            }
                                          >
                                            <NumberInputField
                                              name={`items.${index}.rewardAmount`}
                                              placeholder="10.0"
                                              fontSize="sm"
                                            />
                                            <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                            </NumberInputStepper>
                                          </NumberInput>
                                        </FormControl>
                                      </GridItem>
                                      <GridItem colSpan={1}>
                                        <FormControl>
                                          <NumberInput
                                            value={item.turnAmount}
                                            onChange={(valueString) =>
                                              setFieldValue(
                                                `items.${index}.turnAmount`,
                                                valueString,
                                              )
                                            }
                                          >
                                            <NumberInputField
                                              name={`items.${index}.turnAmount`}
                                              placeholder="2"
                                              fontSize="sm"
                                            />
                                            <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                            </NumberInputStepper>
                                          </NumberInput>
                                        </FormControl>
                                      </GridItem>
                                      <GridItem
                                        colSpan={1}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="end"
                                      >
                                        <IconButton
                                          variant="outline"
                                          borderColor={"red.500"}
                                          color={"red.500"}
                                          bg="#1E252C"
                                          aria-label="delete"
                                          size="md"
                                          icon={<DeleteIcon />}
                                          onClick={() => remove(index)}
                                        />
                                      </GridItem>
                                    </React.Fragment>
                                  ))}
                                </Grid>
                              </CardBody>
                            </Element>
                          </Card>
                        </Animated>
                      </>
                    )}
                  </FieldArray>
                  <Animated
                    justify="flex-start"
                    pt="15px"
                    duration={0.4}
                    delay={1.2}
                  >
                    <Button
                      width={{ base: "100%", md: "auto" }}
                      minW={{ base: "none", md: "150px" }}
                      background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                      borderRadius="33px"
                      fontSize="14px"
                      fontWeight="normal"
                      type="submit"
                      isLoading={isSaving}
                    >
                      บันทึกข้อมูล
                    </Button>
                  </Animated>
                </Stack>
              </Form>
            )}
          </Formik>
        )}
      </Card>
    </Flex>
  );
};

export default SpinnerSetting;
