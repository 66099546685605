import {
  fetchMarketingPromotionReport,
  fetchPromotionReport,
} from "services/member-report/promotionReportService";
import { MARKETING_ROLE } from "variables/system";

export const usePromotionReport = (role) => {
  const fetchData = async (pageIndex, pageSize, search) => {
    const response =
      role !== MARKETING_ROLE
        ? await fetchPromotionReport(pageIndex, pageSize, search)
        : await fetchMarketingPromotionReport(pageIndex, pageSize, search);

    return response;
  };

  return {
    fetchData,
  };
};
