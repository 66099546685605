import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { CheckIcon, CrossIcon, QrCodeIcon } from "components/Icons/Icons";

const DepositEditButtons = ({ row, handleModalOpen }) => {
  const status = row.original.status;
  const image = row.original.image;
  const showBlockAutoButtons = status === "BLOCK_AUTO";
  const showNotSureButtons = status === "NOT_SURE";
  const showQr = image !== null;

  return (
    <>
      <HStack spacing={1}>
        {showBlockAutoButtons && (
          <>
            <Button
              leftIcon={<Icon as={CheckIcon} boxSize={3} color="green.500" />}
              variant="outline"
              borderColor="green.500"
              bg="gray.800"
              size="xs"
              fontSize="xs"
              px={2}
              py={3}
              onClick={() =>
                handleModalOpen(
                  "approveBlockAutoTransactionModal",
                  row.original,
                )
              }
            >
              <Text fontWeight="normal" color="green.500" fontSize="12px">
                อนุมัติ
              </Text>
            </Button>
            <Button
              leftIcon={<Icon as={CrossIcon} boxSize={2} color="red.500" />}
              variant="outline"
              borderColor="red.500"
              bg="gray.800"
              size="xs"
              fontSize="xs"
              px={2}
              py={3}
              onClick={() =>
                handleModalOpen("rejectBlockAutoTransactionModal", row.original)
              }
            >
              <Text fontWeight="normal" color="red.500" fontSize="12px">
                ยกเลิก
              </Text>
            </Button>
          </>
        )}
        {showNotSureButtons && (
          <>
            <Button
              leftIcon={<Icon as={CheckIcon} boxSize={3} color="green.500" />}
              variant="outline"
              borderColor="green.500"
              bg="gray.800"
              size="xs"
              fontSize="xs"
              px={2}
              py={3}
              onClick={() =>
                handleModalOpen("approveInvalidTransactionModal", row.original)
              }
            >
              <Text fontWeight="normal" color="green.500" fontSize="12px">
                อนุมัติ
              </Text>
            </Button>
            <Button
              leftIcon={<Icon as={CrossIcon} boxSize={2} color="red.500" />}
              variant="outline"
              borderColor="red.500"
              bg="gray.800"
              size="xs"
              fontSize="xs"
              px={2}
              py={3}
              onClick={() =>
                handleModalOpen("rejectInvalidTransactionModal", row.original)
              }
            >
              <Text fontWeight="normal" color="red.500" fontSize="12px">
                ยกเลิก
              </Text>
            </Button>
          </>
        )}

        {showQr && (
          <Button
            leftIcon={<Icon as={QrCodeIcon} boxSize={3} color="#8C9592" />}
            variant="outline"
            borderColor="#8C9592"
            bg="gray.800"
            size="xs"
            fontSize="xs"
            px={2}
            py={3}
            onClick={() => handleModalOpen("qrCodeModal", row.original)}
          >
            <Text fontWeight="normal" color="#8C9592" fontSize="12px">
              QR
            </Text>
          </Button>
        )}
      </HStack>
    </>
  );
};

export default DepositEditButtons;
