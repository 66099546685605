import { callapi } from "../../utils/APIUtils";

export const addMember = (payload) => {
  return callapi("/api/admin/webuser", "POST", payload);
};

export const updateMember = (id, payload) => {
  return callapi(`/api/admin/webuser/${id}`, "PUT", payload);
};

export const fetchBanks = (type, nationality = "thai") => {
  return callapi(`/api/master/${type}?nationality=${nationality}`, "GET", {});
};

export const createMistakeCredit = (payload) => {
  return callapi("/api/admin/mistake", "POST", payload);
};

export const resetPassword = (id) => {
  return callapi(`/api/admin/webuser/reset/${id}`, "PUT");
};
