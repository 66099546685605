import {
  Box,
  Button,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, // Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text, // useBreakpointValue,
} from "@chakra-ui/react";
import CardFooter from "components/Card/CardFooter";
import React, { useEffect, useState } from "react";
import { getProviderNiceName } from "utils/CommonUtils";

const CommissionSettingModal = ({
  isOpen,
  onClose,
  modalData,
  handleSubmit,
}) => {
  const [localData, setLocalData] = useState(modalData);

  useEffect(() => {
    setLocalData(modalData);
  }, [modalData]);

  const handleInputChange = (event, provider) => {
    const { value } = event.target;
    const updatedData = localData.map((item) =>
      item.provider === provider ? { ...item, percent: value } : item,
    );
    setLocalData(updatedData);
  };

  // const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const handleSave = () => {
    handleSubmit(localData);
    onClose();
  };

  const renderProviders = (type, label) => (
    <>
      <Text fontSize="18px" fontWeight="bold" mt={2} pb={6} color="green.500">
        รายชื่อค่าย - {label}
      </Text>
      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(6, 1fr)" }}
        gap={3}
      >
        {localData
          .filter((provider) => provider[type])
          .map((provider) => (
            <GridItem key={provider.provider}>
              <Box mb={4}>
                <Text fontSize="xs" color="#fff">
                  {getProviderNiceName(provider.provider)}
                </Text>
                <InputGroup>
                  <Input
                    name={provider.provider}
                    value={provider.percent}
                    onChange={(e) => handleInputChange(e, provider.provider)}
                  />
                  <InputRightElement width="3rem">
                    <Text>%</Text>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </GridItem>
          ))}
      </Grid>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"4xl"}
      scrollBehavior={"inside"}
      isCentered
      overflowY={true}
    >
      <ModalOverlay />
      <ModalContent maxH="95vh">
        <ModalHeader>ตั้งค่าคอมมิชชั่น</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isFitted variant="enclosed" mt={4}>
            <TabList
              overflowX="auto"
              whiteSpace="nowrap"
              overflowY="hidden"
              pb={{ base: "10px", md: "0px" }}
              maxWidth={{ base: "375px", md: "100%" }}
            >
              <Tab
                _selected={{
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                  border: "none",
                  fontWeight: "bold",
                }}
                backgroundColor="#373F48"
                marginLeft={{ base: "0px", md: "55px" }}
                marginRight="5px"
                color="#8C9592"
                minWidth={{ base: "80px", md: "136px" }}
                fontSize={{ base: "14px", md: "18px" }}
                borderTopRadius="8px"
                borderBottomRadius={{ base: "8px", md: "0px" }}
              >
                Slot
              </Tab>
              <Tab
                _selected={{
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                  border: "none",
                  fontWeight: "bold",
                }}
                backgroundColor="#373F48"
                marginRight="5px"
                color="#8C9592"
                minWidth={{ base: "80px", md: "136px" }}
                fontSize={{ base: "14px", md: "18px" }}
                borderTopRadius="8px"
                borderBottomRadius={{ base: "8px", md: "0px" }}
              >
                Casino
              </Tab>
              <Tab
                _selected={{
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                  border: "none",
                  fontWeight: "bold",
                }}
                backgroundColor="#373F48"
                marginRight="5px"
                color="#8C9592"
                minWidth={{ base: "80px", md: "136px" }}
                fontSize={{ base: "14px", md: "18px" }}
                borderTopRadius="8px"
                borderBottomRadius={{ base: "8px", md: "0px" }}
              >
                Card
              </Tab>
              <Tab
                _selected={{
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                  border: "none",
                  fontWeight: "bold",
                }}
                backgroundColor="#373F48"
                marginRight="5px"
                color="#8C9592"
                minWidth={{ base: "80px", md: "136px" }}
                fontSize={{ base: "14px", md: "18px" }}
                borderTopRadius="8px"
                borderBottomRadius={{ base: "8px", md: "0px" }}
              >
                Fishing
              </Tab>
              <Tab
                _selected={{
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                  border: "none",
                  fontWeight: "bold",
                }}
                marginRight={{ base: "0px", md: "55px" }}
                backgroundColor="#373F48"
                color="#8C9592"
                minWidth={{ base: "80px", md: "136px" }}
                fontSize={{ base: "14px", md: "18px" }}
                borderTopRadius="8px"
                borderBottomRadius={{ base: "8px", md: "0px" }}
              >
                Sport
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel pl={0} pr={0}>
                {renderProviders("isSlot", "Slot")}
              </TabPanel>
              <TabPanel pl={0} pr={0}>
                {renderProviders("isLive", "Casino")}
              </TabPanel>
              <TabPanel pl={0} pr={0}>
                {renderProviders("isCard", "Card")}
              </TabPanel>
              <TabPanel pl={0} pr={0}>
                {renderProviders("isFishing", "Fish")}
              </TabPanel>
              <TabPanel pl={0} pr={0}>
                {renderProviders("isSport", "Sport")}
              </TabPanel>
            </TabPanels>
          </Tabs>
          <CardFooter pl={0} pr={0} pb={6} justifyContent="center">
            <Button
              bg="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              onClick={handleSave}
              w="100%"
              pt={0}
              fontWeight="normal"
              fontSize="14px"
            >
              บันทึกข้อมูล
            </Button>
          </CardFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CommissionSettingModal;
