import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { CreateIcon } from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import SimpleTable from "components/Tables/SimpleTable";
import { Field, Form, Formik } from "formik";
import { useFooterSetting } from "hooks/business/website-setting/useFooterSetting";
import React, { useState } from "react";
import { footerSettingColumnsData } from "variables/columns/website-setting/footerSettingColumnsData";

import DeleteFooterIconModal from "./components/DeleteFooterIconModal";
import ManageFooterIconModal from "./components/ManageFooterIconModal";

export const FooterSetting = ({ pageName }) => {
  const [selectedFooterIcon, setSelectedFooterIcon] = useState(null);

  const {
    footerSettingData,
    footerIconsData,
    isFooterIconsLoading,
    handleSave,
    handleSaveFooterIcon,
    handleDeleteFooterIcon,
    isSaving,
    isFooterIconDeleting,
  } = useFooterSetting();

  const [modalState, setModalState] = useState({
    manageFooterIconModalOpen: false,
    deleteFooterIconModalOpen: false,
  });

  const handleModalOpen = (modal, icon = null) => {
    setSelectedFooterIcon(icon);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedFooterIcon(null);
  };

  const handleSubmit = (values) => {
    const req = { ...footerSettingData, ...values };
    handleSave(req);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Stack direction="column" spacing="24px" w="100%">
        <Formik
          initialValues={{
            title: footerSettingData?.title || "",
            mobileDescription: footerSettingData?.mobileDescription || "",
            desktopDescription: footerSettingData?.desktopDescription || "",
            status: footerSettingData?.status || "false",
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Card w="100%">
                <CardHeader>
                  <Text fontSize="18px" fontWeight="bold" color="#fff">
                    {pageName}
                  </Text>
                </CardHeader>
                <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
                  <CardBody>
                    <Grid
                      templateColumns={{
                        base: "1fr",
                        md: "repeat(2, 1fr)",
                      }}
                      gap="24px"
                      w="100%"
                    >
                      <GridItem>
                        <FormControl>
                          <FormLabel>Title</FormLabel>
                          <Field
                            as={Input}
                            name="title"
                            placeholder="eg. Jackson"
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem display={{ base: "none", md: "block" }} />
                      <GridItem>
                        <FormControl>
                          <FormLabel>Mobile Description</FormLabel>
                          <Field
                            as={Textarea}
                            borderRadius="10px"
                            bg="#1E252C"
                            name="mobileDescription"
                            minHeight="145px"
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>Desktop Description</FormLabel>
                          <Field
                            as={Textarea}
                            borderRadius="10px"
                            bg="#1E252C"
                            name="desktopDescription"
                            minHeight="145px"
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <Flex>
                            <Switch
                              isChecked={values.status !== "false"}
                              onChange={(e) =>
                                setFieldValue(
                                  "status",
                                  e.target.checked.toString(),
                                )
                              }
                            />
                            <Text ml={4}>สถานะเปิดใช้งาน</Text>
                          </Flex>
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </CardBody>
                </Animated>
                <Animated
                  justify="flex-start"
                  pt="28px"
                  duration={0.4}
                  delay={0.6}
                >
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    borderRadius="33px"
                    type="submit"
                    width={{ base: "100%", md: "auto" }}
                    minW={{ base: "none", md: "175px" }}
                    fontWeight="normal"
                    fontSize="14px"
                    isLoading={isSaving}
                  >
                    บันทึกข้อมูล
                  </Button>
                </Animated>
              </Card>
            </Form>
          )}
        </Formik>
        <Card>
          <Flex
            justifyContent="space-between"
            direction={{ base: "column", md: "row" }}
            pb={6}
          >
            <CardHeader>
              <Text fontSize="18px" fontWeight="bold" color="#fff">
                จัดจาร ICONS
              </Text>
            </CardHeader>
            <Button
              onClick={() => handleModalOpen("manageFooterIconModal")}
              background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              variant="solid"
              borderRadius="33px"
              fontSize="14px"
              fontWeight="normal"
              w="150px"
            >
              <Icon as={CreateIcon} mr="5px" />
              เพิ่มไอคอนใหม่
            </Button>
          </Flex>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody h="285px" pb={6}>
              <SimpleTable
                tableData={footerIconsData}
                columnsData={footerSettingColumnsData(handleModalOpen)}
                isLoading={isFooterIconsLoading}
              />
            </CardBody>
          </Animated>
        </Card>
        <DeleteFooterIconModal
          isOpen={modalState.deleteFooterIconModalOpen}
          onClose={() => handleModalClose("deleteFooterIconModal")}
          icon={selectedFooterIcon}
          handleSubmit={handleDeleteFooterIcon}
          isSubmitting={isFooterIconDeleting}
        />
        <ManageFooterIconModal
          isOpen={modalState.manageFooterIconModalOpen}
          onClose={() => handleModalClose("manageFooterIconModal")}
          icon={selectedFooterIcon}
          handleSubmit={handleSaveFooterIcon}
        />
      </Stack>
    </Flex>
  );
};

export default FooterSetting;
