import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  fetchAdminProfile,
  updateAdminProfile,
} from "../../services/admin/adminProfileService";
import { QueryKeys } from "../../variables/queryKeys";
import { APP_PROFILE, ERROR_MESSAGE } from "../../variables/system";
import { useErrorHandler } from "../common/useErrorHandler";
import { useSuccessHandler } from "../common/useSuccessHandler";

export const useAdminProfile = () => {
  const queryClient = useQueryClient();
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const [adminData, setAdminData] = useState({});

  const { data, isLoading, error } = useQuery(
    QueryKeys.ADMIN_PROFILE,
    async () => {
      const profile = localStorage.getItem(APP_PROFILE);
      if (!profile) handleError(error, ERROR_MESSAGE);
      const parsedProfile = JSON.parse(profile);
      const username = parsedProfile?.username;
      const response = await fetchAdminProfile(username);
      return response.data.data;
    },
    {
      onSuccess: (data) => setAdminData(data),
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const { mutate: saveAdminProfile, isLoading: isSaving } = useMutation(
    updateAdminProfile,
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(QueryKeys.ADMIN_PROFILE);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const handleSubmit = (values) => {
    const req = { ...adminData, ...values };
    saveAdminProfile(req);
  };

  return {
    data,
    isLoading,
    error,
    handleSubmit,
    isSaving,
  };
};
