import { Box } from "@chakra-ui/react";

const Animated = ({
  children,
  animation = "fadeInUp",
  duration = "1s",
  delay = "0s",
  ...props
}) => {
  return (
    <Box
      className={`animated ${animation}`}
      style={{
        animationDuration: duration,
        animationDelay: delay,
        WebkitAnimationDuration: duration,
        WebkitAnimationDelay: delay,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Animated;
