import { Badge, IconButton, Input, Switch } from "@chakra-ui/react";
import { SystemSettingIcon } from "components/Icons/Icons";
import { useFormikContext } from "formik";
import { useMemberLevel } from "hooks/business/member-management/useMemberLevel";
import {
  getMemberLevelColorScheme,
  getMemberLevelName,
} from "utils/CommonUtils";

export const commissionSettingColumnsData = (handleModalOpen) => {
  return [
    {
      Header: "สถานะ",
      accessor: "active",
      textAlign: "left",
      justifyContent: "flex-start",
      disableSortBy: true,
      width: "35px",
      Cell: ({ row }) => {
        const { values, setFieldValue } = useFormikContext();
        const isActive = values.commissions[row.index].active;

        return (
          <Switch
            key={isActive}
            isChecked={isActive}
            onChange={() => {
              const newValue = !isActive;
              setFieldValue(`commissions.${row.index}.active`, newValue);
            }}
          />
        );
      },
    },
    {
      Header: "เลเวล",
      accessor: "level",
      textAlign: "left",
      justifyContent: "flex-start",
      disableSortBy: true,
      width: "375px",
      Cell: ({ row }) => {
        const { memberLevelData } = useMemberLevel();
        return (
          <Badge
            bg={getMemberLevelColorScheme(row.original.level.toString())}
            w="75px"
            h="22px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="normal"
          >
            {getMemberLevelName(memberLevelData, row.original.level)}
          </Badge>
        );
      },
    },
    {
      Header: "จ่ายขั้นต่ำ (ต่อการเดิมพัน)",
      accessor: "minComm",
      textAlign: "right",
      justifyContent: "flex-end",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { setFieldValue } = useFormikContext();
        return (
          <Input
            borderRadius="6px"
            value={row.original.minComm}
            onBlur={(e) => {
              const value = parseFloat(e.target.value);
              setFieldValue(`commissions.${row.index}.minComm`, value);
            }}
            onChange={(valueString) => {
              const value = parseFloat(valueString.target.value);
              setFieldValue(`commissions.${row.index}.minComm`, value);
            }}
          />
        );
      },
    },
    {
      Header: "จ่ายสูงสุด (ต่อการเดิมพัน)",
      accessor: "maxComm",
      textAlign: "right",
      justifyContent: "flex-end",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { setFieldValue } = useFormikContext();
        return (
          <Input
            borderRadius="6px"
            value={row.original.maxComm}
            onBlur={(e) => {
              const value = parseFloat(e.target.value);
              setFieldValue(`commissions.${row.index}.maxComm`, value);
            }}
            onChange={(valueString) => {
              const value = parseFloat(valueString.target.value);
              setFieldValue(`commissions.${row.index}.maxComm`, value);
            }}
          />
        );
      },
    },
    {
      Header: "",
      accessor: "settings",
      disableSortBy: true,
      textAlign: "right",
      justifyContent: "flex-end",
      width: "35px",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          border="none"
          onClick={() => handleModalOpen(row.original.setting, row.index)}
          icon={<SystemSettingIcon h="24px" w="24px" color="green.500" />}
        />
      ),
    },
  ];
};
