import { useToast } from "@chakra-ui/react";

import { SUCCESS_MESSAGE } from "../../variables/system";

export const useSuccessHandler = () => {
  const toast = useToast();
  return (message) => {
    toast({
      title: SUCCESS_MESSAGE,
      position: "top-right",
      description: message,
      status: "success",
      duration: 1000,
      isClosable: true,
    });
  };
};
