import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { validateField, validateObject } from "utils/CommonUtils";

const ManageTrueWalletModal = ({
  isOpen,
  onClose,
  trueWallet = null,
  handleSubmit,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const modalSize = useBreakpointValue({ base: "xl", md: "lg" });

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent maxH="100vh">
        <ModalHeader>เพิ่ม / แก้ไข ทรูวอลเล็ต</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              id: trueWallet ? trueWallet.id : "",
              phoneNumber: trueWallet ? trueWallet.phoneNumber : "",
              name: trueWallet ? trueWallet.name : "",
              bankGroup: trueWallet ? trueWallet.bankGroup : "",
              newUserFlag: trueWallet ? trueWallet.newUserFlag : true,
              active: trueWallet ? trueWallet.active : true,
              key: trueWallet ? trueWallet.key : "",
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, trueWallet, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={2}>
                    <Text
                      fontSize="16px"
                      fontWeight="bold"
                      pt={4}
                      pb={2}
                      color="#31AA93"
                    >
                      ข้อมูลบัญชี
                    </Text>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Field name="name" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          isRequired
                        >
                          <FormLabel>ชื่อ</FormLabel>
                          <Input {...field} placeholder="ชื่อ" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Field name="phoneNumber" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.phoneNumber && form.touched.phoneNumber
                          }
                          isRequired
                        >
                          <FormLabel>เลขที่บัญชี True wallet</FormLabel>
                          <Input {...field} placeholder="เลขที่บัญชี" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Field
                      name="key"
                      validate={(values) => validateObject(values, trueWallet)}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.key && form.touched.key && !trueWallet
                          }
                          isRequired={!trueWallet}
                        >
                          <FormLabel>Key</FormLabel>
                          <Input
                            {...field}
                            placeholder="09cebc4c-5371-4952-b83a-ec3ce7d27XXX"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Field name="bankGroup" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankGroup && form.touched.bankGroup
                          }
                          isRequired
                        >
                          <FormLabel>กลุ่ม</FormLabel>
                          <Input {...field} placeholder="กลุ่ม" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="newUserFlag">
                      {({ field }) => (
                        <FormControl display="flex" alignItems="center">
                          <Switch
                            id="is-bonus-enabled"
                            {...field}
                            isChecked={field.value}
                          />
                          <FormLabel
                            htmlFor="is-bonus-enabled"
                            mb="0"
                            fontSize="16px"
                            ml={4}
                          >
                            สำหรับยูสเซอร์ใหม่
                          </FormLabel>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="active">
                      {({ field }) => (
                        <FormControl display="flex" alignItems="center">
                          <Switch
                            id="is-no-bonus"
                            {...field}
                            isChecked={field.value}
                          />
                          <FormLabel
                            htmlFor="is-no-bonus"
                            mb="0"
                            fontSize="16px"
                            ml={4}
                          >
                            สถานะเปิดใช้งาน
                          </FormLabel>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    fontWeight="normal"
                    fontSize="14px"
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageTrueWalletModal;
