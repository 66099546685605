import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { saveAs } from "file-saver";
import React from "react";
import { useCSVDownloader } from "react-papaparse";
import * as XLSX from "xlsx";

import { ExportIcon } from "../Icons/Icons";

const FileExportDropdown = ({ data, filename }) => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const handleExcelExport = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(dataBlob, `${filename}.xlsx`);
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        pl={6}
        pr={6}
        fontSize="14px"
        color="rgba(140, 149, 146, 1)"
        backgroundColor="rgba(55, 63, 72, 1)"
        borderRadius="33px"
        leftIcon={
          <ExportIcon color="rgba(140, 149, 146, 1)" h={"22px"} w={"22px"} />
        }
      >
        Export
      </MenuButton>
      <MenuList>
        <MenuItem>
          <CSVDownloader filename={filename} data={data} type={Type.Button}>
            <Text fontWeight="normal" fontSize="sm">
              Export as CSV
            </Text>
          </CSVDownloader>
        </MenuItem>
        <MenuItem onClick={handleExcelExport}>
          <Text fontWeight="normal" fontSize="sm">
            Export as Excel
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default FileExportDropdown;
