import { useQuery } from "react-query";
import { fetchCommissionReport } from "services/member-report/commissionReportService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useCommissionReport = (search) => {
  const handleError = useErrorHandler();

  return useQuery(
    [
      QueryKeys.COMMISSION_REPORT,
      search.createdDateFrom,
      search.createdDateTo,
      search.type,
    ],
    async () => {
      const response = await fetchCommissionReport(search);
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );
};
