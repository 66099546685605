import { Text } from "@chakra-ui/react";
import React from "react";

const PageHeader = ({ pageName }) => {
  return (
    <Text
      fontSize="24px"
      fontWeight="bold"
      color="green.500"
      mb="18px"
      mt="6px"
    >
      {pageName}
    </Text>
  );
};

export default PageHeader;
