import { callListApi } from "../../utils/APIUtils";

export const fetchDashboardSummary = (payload) => {
  return callListApi("/api/admin/dashboard-summary", "POST", payload);
};

export const fetchDashboardMarketingSummary = (payload) => {
  return callListApi("/api/admin/marketing/dashboard-summary", "POST", payload);
};

export const fetchProfitGraph = (payload) => {
  return callListApi("/api/admin/profit-report", "POST", payload);
};

export const fetchMarketingProfitGraph = (payload) => {
  return callListApi("/api/admin/marketing/profit-report", "POST", payload);
};

export const fetchTopTransaction = (payload) => {
  return callListApi("/api/admin/count-refill", "POST", payload);
};
export const fetchMarketingTopTransaction = (payload) => {
  return callListApi("/api/admin/marketing/count-refill", "POST", payload);
};

export const fetchLast20DepositHistory = () => {
  return callListApi("/api/admin/depositHistory/last20", "POST", {});
};

export const fetchLast20MarketingDepositHistory = (payload) => {
  return callListApi(
    "/api/admin/marketing/depositHistory/last20",
    "POST",
    payload,
  );
};

export const fetchLast20MarketingWithdrawHistory = (payload) => {
  return callListApi(
    "/api/admin/marketing/withdrawHistory/last20",
    "POST",
    payload,
  );
};

export const fetchLast20WithdrawHistory = () => {
  return callListApi("/api/admin/withdrawHistory/last20", "POST", {});
};
