import { formatDate } from "utils/DateformatUtils";

export const staffLoginHistoryColumnsData = () => {
  return [
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      textAlign: "left",
      justifyContent: "flex-start",
      minWidth: "65px",
    },
    {
      Header: "ตำแหน่ง",
      accessor: "role",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "เข้าสู่ระบบล่าสุด",
      accessor: "loginDate",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => formatDate(value),
    },
  ];
};
