import moment from "moment/moment";
import { useQuery } from "react-query";
import {
  fetchMarketingProfitGraph,
  fetchProfitGraph,
} from "services/dashboard/dashboardService";
import { createLineChartOptions } from "utils/ChartUtils";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE, MARKETING_ROLE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useProfitGraph = (selectedDates, role, mid = null) => {
  const handleError = useErrorHandler();

  const { data: lineChartData, isLoading: isMemberLineDataLoading } = useQuery(
    [QueryKeys.PROFIT_GRAPH, selectedDates[0]],
    async () => {
      const req = {
        value: moment(selectedDates[0]).format("yyyy-MM"),
        mid: mid,
      };
      const res =
        role !== MARKETING_ROLE
          ? await fetchProfitGraph(req)
          : await fetchMarketingProfitGraph(req);
      return {
        chartOptions: createLineChartOptions({
          xAxisCategories: res.data.data.labelsWithdraw,
        }),
        memberLineData: [
          { name: "Deposit", data: res.data.data.dataDeposit },
          { name: "Withdraw", data: res.data.data.dataWithdraw },
        ],
      };
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  return { lineChartData, isMemberLineDataLoading };
};
