import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    red: {
      200: "rgba(234, 84, 85, 1)", // ColorScheme Value
      500: "rgba(187, 52, 76, 1)",
    },
    gray: {
      500: "rgba(140, 149, 146, 1)",
      600: "rgb(45,53,61,1)",
      700: "rgba(38, 45, 53, 1)",
      800: "rgba(29, 34, 41, 1)", // background overriding
      900: "rgba(29, 34, 41, 1)",
    },
    teal: {
      500: "rgba(79, 209, 197,1)",
    },
    green: {
      200: "rgba(40, 199, 111, 1)", // ColorScheme Value
      500: "rgba(49, 170, 147, 1)",
    },
  },
  styles: {
    global: (props) => {
      const isMobile = window.innerWidth <= 768;
      return {
        body: {
          backgroundColor: mode("white", "rgb(29,31,36,1)")(props),
          backgroundImage:
            document.documentElement.layout === "auth"
              ? mode(
                  "url('https://asg-cdn.sgp1.cdn.digitaloceanspaces.com/backoffice/bg-login.png')",
                  "url('https://asg-cdn.sgp1.cdn.digitaloceanspaces.com/backoffice/bg-login.png')",
                )(props)
              : isMobile
              ? "none"
              : mode(
                  "none",
                  "linear-gradient(180deg, rgba(129, 255, 225, 0.13) 0%, rgba(28, 31, 36, 0) 30.43%)",
                )(props),
          backgroundSize:
            document.documentElement.layout === "auth" ? "cover" : "100% 40vh",
          backgroundPosition:
            document.documentElement.layout === "auth" ? "center" : "top",
          backgroundRepeat: "no-repeat",
          fontFamily: "'LINESeedSansTH', sans-serif",
        },
        html: {
          fontFamily: "'LINESeedSansTH', sans-serif",
        },
      };
    },
  },
};
