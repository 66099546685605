import { Text } from "@chakra-ui/react";

export const promotionReportColumnsData = () => {
  return [
    {
      Header: "ชื่อโปรโมชั่น",
      accessor: "name",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "จำนวนครั้ง",
      accessor: "countPromotion",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "จำนวนโบนัส",
      accessor: "sumBonus",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text
          color={value === 0 ? "#fff" : value < 0 ? "red.500" : "green.500"}
          fontWeight="bold"
        >
          {value}
        </Text>
      ),
    },
  ];
};
