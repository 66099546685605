import {
  Button,
  Center,
  Flex,
  FormControl,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Field } from "formik";
import React, { useEffect, useState } from "react";

const ColorPicker = ({ colors, initialColor, fieldName }) => {
  const [color, setColor] = useState(initialColor || "");

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  return (
    <Field name={fieldName}>
      {({ field, form }) => (
        <FormControl>
          <Popover variant="picker">
            <PopoverTrigger>
              <Flex
                align="center"
                background="rgba(30, 37, 44, 1)"
                w="175px"
                padding="4px 8px"
                borderRadius="full"
                cursor="pointer"
                border="1px solid rgba(55, 63, 72, 1)"
              >
                <Button
                  aria-label={field.value}
                  background={color}
                  height="32px"
                  width="32px"
                  padding={0}
                  minWidth="unset"
                  borderRadius="33px"
                  mr={3}
                ></Button>
                <Text fontSize="sm" color="#fff">
                  {field.value || "เลือกสี"}
                </Text>
              </Flex>
            </PopoverTrigger>
            <PopoverContent width="170px">
              <PopoverArrow bg={field.value} />
              <PopoverCloseButton color="white" />
              <PopoverHeader
                height="100px"
                backgroundColor={field.value}
                borderTopLeftRadius={5}
                borderTopRightRadius={5}
                color="white"
              >
                <Center height="100%">{field.value}</Center>
              </PopoverHeader>
              <PopoverBody height="120px">
                <SimpleGrid columns={5} spacing={2}>
                  {colors.map((c) => (
                    <Button
                      key={c}
                      aria-label={c}
                      background={c}
                      height="22px"
                      width="22px"
                      padding={0}
                      minWidth="unset"
                      borderRadius={3}
                      _hover={{ background: c }}
                      onClick={() => {
                        setColor(c);
                        form.setFieldValue(field.name, c);
                      }}
                    ></Button>
                  ))}
                </SimpleGrid>
                <Input
                  borderRadius={3}
                  marginTop={3}
                  placeholder="red.100"
                  size="sm"
                  value={field.value}
                  onChange={(e) => {
                    setColor(e.target.value);
                    form.setFieldValue(field.name, e.target.value);
                  }}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </FormControl>
      )}
    </Field>
  );
};

export default ColorPicker;
