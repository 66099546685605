import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useDepositTransaction } from "hooks/business/transaction-report/useDepositTransaction";
import { useSearch } from "hooks/common/useSearch";
import React, { useState } from "react";
import { QueryClientProvider, useQueryClient } from "react-query";
import { invalidTransactionReportColumnsData } from "variables/columns/transaction-report/invalidTransactionReportColumnsData";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";
import { DepositTransactionTypes } from "variables/types";

import ApproveInvalidTransactionModal from "./components/ApproveInvalidTransactionModal";
import RejectInvalidTransactionModal from "./components/RejectInvalidTransactionModal";

const InvalidTransactionReport = ({ pageName }) => {
  const queryClient = useQueryClient();
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const [modalState, setModalState] = useState({
    approveInvalidTransactionModalOpen: false,
    rejectInvalidTransactionModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const { fetchData, downloadableData, handleInvalidTransaction } =
    useDepositTransaction(
      role,
      QueryKeys.INVALID_TRANSACTION_REPORT,
      invalidTransactionReportColumnsData,
      DepositTransactionTypes.NOT_SURE,
    );

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              pb={{ base: "14px", md: "0px" }}
              color="#fff"
            >
              เช็ครายการไม่พบบัญชี
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <QueryClientProvider client={queryClient}>
              <SearchTableRemote
                query={QueryKeys.INVALID_TRANSACTION_REPORT}
                columnsData={invalidTransactionReportColumnsData(
                  handleModalOpen,
                )}
                fetchData={fetchData}
                search={search}
                setSearch={setSearch}
                showExport={true}
                downloadableData={downloadableData}
                filename={pageName}
              />
            </QueryClientProvider>
          </CardBody>
        </Animated>
      </Card>
      <ApproveInvalidTransactionModal
        isOpen={modalState.approveInvalidTransactionModalOpen}
        onClose={() => handleModalClose("approveInvalidTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleInvalidTransaction}
      />
      <RejectInvalidTransactionModal
        isOpen={modalState.rejectInvalidTransactionModalOpen}
        onClose={() => handleModalClose("rejectInvalidTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleInvalidTransaction}
      />
    </Flex>
  );
};

export default InvalidTransactionReport;
