import { Box, Button, Flex, Text, useClipboard } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import React from "react";

export const MemberProfileCard = ({ username, provider }) => {
  const { onCopy } = useClipboard(username);
  return (
    <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
      <Card padding="15px 15px" bg="#262D35" mt="12px" borderRadius="8px">
        <Flex align="center" justifyContent="space-between">
          <Flex align="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              boxSize="35px"
              borderRadius="full"
              bg="green.500"
              color="white"
              fontWeight="bold"
              fontSize="20px"
            >
              {username?.charAt(0).toUpperCase()}
            </Box>
            <Flex ml="24px" direction="column">
              <Text color="#8C9592" fontSize="14px">
                ยูสเซอร์เนม{" "}
                {provider === "Ufabet"
                  ? "Ufabet"
                  : provider === "Betflix"
                  ? "Betflix"
                  : ""}
              </Text>
              <Text color="#fff" fontSize="16px" mt="0px" fontWeight="bold">
                {username}
              </Text>
            </Flex>
          </Flex>
          <Button
            borderColor={"green.500"}
            color={"green.500"}
            w="64px"
            h="27px"
            variant="outline"
            fontSize="14px"
            fontWeight="normal"
            onClick={onCopy}
          >
            คัดลอก
          </Button>
        </Flex>
      </Card>
    </Animated>
  );
};
