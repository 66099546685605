import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchGameSetting,
  updateGameSetting,
} from "services/system-setting/gameSettingService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";
import { GameTypes } from "variables/types";

import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useGameSetting = () => {
  const queryClient = useQueryClient();
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const [slotProviderData, setSlotProviderData] = useState(null);
  const [casinoProviderData, setCasinoProviderData] = useState(null);
  const [sportProviderData, setSportProviderData] = useState(null);
  const [cardProviderData, setCardProviderData] = useState(null);

  const sortData = (data) => {
    return data.sort((a, b) => a.provider.localeCompare(b.provider));
  };

  const { isLoading } = useQuery(
    QueryKeys.GAME_SETTING,
    async () => {
      const response = await fetchGameSetting();
      const { gameSettingList } = response.data.data;
      return {
        slot: sortData(
          gameSettingList.filter((setting) => setting.type === GameTypes.SLOT),
        ),
        live: sortData(
          gameSettingList.filter((setting) => setting.type === GameTypes.LIVE),
        ),
        sport: sortData(
          gameSettingList.filter((setting) => setting.type === GameTypes.SPORT),
        ),
        card: sortData(
          gameSettingList.filter((setting) => setting.type === GameTypes.CARD),
        ),
      };
    },
    {
      onSuccess: (data) => {
        setSlotProviderData(data.slot);
        setCasinoProviderData(data.live);
        setSportProviderData(data.sport);
        setCardProviderData(data.card);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation(updateGameSetting, {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries(QueryKeys.GAME_SETTING);
    },
    onError: (error) => {
      handleError(error, ERROR_MESSAGE);
      queryClient.invalidateQueries(QueryKeys.GAME_SETTING);
    },
  });

  const handleToggle = (provider, type, isEnable) => {
    const req = { isEnable, provider, type };
    mutation.mutate(req);
  };

  return {
    slotProviderData,
    casinoProviderData,
    sportProviderData,
    cardProviderData,
    isLoading,
    handleToggle,
    isSaving: mutation.isLoading,
  };
};
