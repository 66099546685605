import { callListApi, callapi } from "../../utils/APIUtils";
import {
  buildBasePayload,
  buildFetchJackpotHistoryPayload,
} from "../../utils/PayloadUtils";

export const fetchJackpot = () => {
  return callapi("/api/admin/jackpot", "GET", {});
};

export const fetchJackpotHistory = async (
  page,
  pageSize,
  search,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildFetchJackpotHistoryPayload,
  );
  const response = await callListApi("/api/admin/jackpot/history", "POST", req)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  return data;
};

export const saveJackpot = (payload) => {
  return callapi("/api/admin/jackpot", "POST", payload);
};

export const verifyJackpot = (payload) => {
  return callapi("/api/admin/jackpot/verify", "POST", payload);
};

export const submitJackpot = (payload) => {
  return callapi("/api/admin/jackpot/submit", "POST", payload);
};
