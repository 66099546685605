export const selectStyles = {
  components: {
    Select: {
      baseStyle: {
        field: {
          backgroundColor: "rgba(30, 37, 44, 1)",
          borderRadius: "33px",
        },
      },
    },
  },
};
