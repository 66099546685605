import { useState } from "react";
import { fetchMemberTransactionReport } from "services/member-report/memberTransactionReportService";
import { mapJsonToTableData } from "utils/CommonUtils";
import { memberTransactionColumnsData } from "variables/columns/member-report/memberTransactionColumnsData";

export const useMemberTransactionReport = () => {
  const [downloadableData, setDownloadableData] = useState([]);

  const fetchData = async (pageIndex, pageSize, search) => {
    const response = await fetchMemberTransactionReport(
      pageIndex,
      pageSize,
      search,
    );
    const formattedRecords = mapJsonToTableData(
      response.results,
      memberTransactionColumnsData(),
    );

    setDownloadableData(formattedRecords);
    return response;
  };

  return {
    fetchData,
    downloadableData,
  };
};
