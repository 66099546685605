import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import { CheckIcon, CrossIcon } from "components/Icons/Icons";

const EditButtons = ({ row, handleModalOpen }) => {
  return (
    <HStack spacing={1}>
      <Button
        leftIcon={<Icon as={CheckIcon} boxSize={4} color="green.500" />}
        variant="outline"
        borderColor="green.500"
        bg="gray.800"
        size="xs"
        fontSize="xs"
        px={2}
        py={3}
        onClick={() =>
          handleModalOpen("approveInvalidTransactionModal", row.original)
        }
      >
        <Text fontWeight="normal" color="green.500" fontSize="12px">
          อนุมัติ
        </Text>
      </Button>
      <Button
        leftIcon={<Icon as={CrossIcon} boxSize={2} color="red.500" />}
        variant="outline"
        borderColor="red.500"
        bg="gray.800"
        size="xs"
        fontSize="xs"
        px={2}
        py={3}
        onClick={() =>
          handleModalOpen("rejectInvalidTransactionModal", row.original)
        }
      >
        <Text fontWeight="normal" color="red.500" fontSize="12px">
          ยกเลิก
        </Text>
      </Button>
    </HStack>
  );
};

export default EditButtons;
