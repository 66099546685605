import {
  Flex,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import AnimatedNumber from "components/AnimatedNumber/AnimatedNumber";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import IconBox from "components/Icons/IconBox";
import React from "react";

const ResponsiveNormalReportsCard = ({
  icon,
  title,
  number,
  isInteger,
  description,
  mode = null,
  isLoading = true,
}) => {
  const cleanNumber = parseFloat(number?.replace(/,/g, ""));
  const mainColor = "rgba(55, 63, 72, 1)";

  return (
    <Card border={`1px solid ${mainColor}`} bgColor="gray.900">
      <CardBody h="100%">
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          w="100%"
          h="100%"
        >
          <Flex justify="left" mb={{ base: 4, md: 0 }}>
            {" "}
            <IconBox w="42px" h="42px" backgroundColor={mainColor}>
              {icon}
            </IconBox>
          </Flex>

          <StatGroup flex="1" ml={{ base: 0, md: 4 }} textAlign="right">
            <Stat>
              <StatNumber>
                {isLoading && !cleanNumber ? (
                  <span className="loader"></span>
                ) : (
                  <AnimatedNumber
                    n={cleanNumber}
                    dp={isInteger ? 0 : 2}
                    mode={mode}
                    fontSize="26px"
                  />
                )}
              </StatNumber>
              <StatLabel fontSize="14px" pt={2}>
                {title}
              </StatLabel>
              <StatHelpText
                color="gray.500"
                fontSize={{ base: "xs", md: "14px" }}
                mb={0}
              >
                {description}
              </StatHelpText>
            </Stat>
          </StatGroup>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ResponsiveNormalReportsCard;
