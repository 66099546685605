import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { getJackpotBackground, validateField } from "utils/CommonUtils";

const ManageJackpotModal = ({
  isOpen,
  onClose,
  jackpot,
  handleSubmit,
  handleVerify,
  isUserVerified,
  verifySuccessMessage,
  verifyUnSuccessMessage,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="center">
            <Flex alignItems="center" justifyContent="left">
              <Text fontSize="20px" color="#fff">
                ให้แจ๊คพ๊อต
              </Text>
            </Flex>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            fontSize="16px"
            fontWeight="bold"
            pt={4}
            pb={6}
            color="green.500"
          >
            ให้รางวัลแจ็คพอต
          </Text>
          <Formik
            initialValues={{
              type: jackpot?.type,
              username: "",
              turnover: 0,
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <FormLabel>ประเภท</FormLabel>
                <Badge
                  bg={getJackpotBackground(jackpot?.type)}
                  w="77px"
                  h="28px"
                  borderRadius="15px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="normal"
                >
                  {jackpot?.type.toUpperCase()}
                </Badge>
                <Field name="username" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.username && form.touched.username}
                      isRequired
                      mt={8}
                    >
                      <FormLabel>ยูสเซอร์เนม</FormLabel>
                      <Flex>
                        <Input
                          {...field}
                          placeholder="ยูสเซอร์เนม"
                          fontSize="sm"
                          borderTopRightRadius="0px"
                          borderBottomRightRadius="0px"
                        />
                        <Button
                          ml="-5"
                          fontSize="14px"
                          background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                          variant="solid"
                          w="113px"
                          h="42px"
                          fontWeight="normal"
                          onClick={() => {
                            handleVerify(field.value);
                          }}
                        >
                          ตรวจสอบ
                        </Button>
                      </Flex>
                      {isUserVerified && (
                        <Text fontSize="xs" color="green.500" mt="2">
                          {verifySuccessMessage}
                        </Text>
                      )}
                      {!isUserVerified && verifyUnSuccessMessage && (
                        <Text fontSize="xs" color="red.500" mt="2">
                          {verifyUnSuccessMessage}
                        </Text>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="turnover">
                  {({ field }) => (
                    <FormControl mt={4}>
                      <FormLabel>จำนวนเทินโอเวอร์</FormLabel>
                      <NumberInput
                        value={props.values.turnOver}
                        onChange={(valueString) =>
                          props.setFieldValue("turnOver", valueString)
                        }
                      >
                        <NumberInputField
                          ref={initialRef}
                          {...field}
                          fontSize="sm"
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    isLoading={props.isSubmitting}
                    type="submit"
                    fontWeight="normal"
                    disabled={!isUserVerified}
                    fontSize="14px"
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageJackpotModal;
