import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Switch,
  Text,
} from "@chakra-ui/react";
import { CalendarIcon, DeleteIcon, EditIcon } from "components/Icons/Icons";
import React from "react";
import { getDeviceText } from "utils/CommonUtils";

const PopupCard = ({ popup, onEdit, onDelete }) => {
  return (
    <Box borderRadius="10px" overflow="hidden" p={6} bg="#1E252C">
      <Image
        src={popup.image || "https://via.placeholder.com/1040"}
        alt={`${popup.name} image`}
        width="100%"
        height="150px"
        objectFit="cover"
        borderRadius="10px"
      />

      <Box pt={4} pb={2}>
        <Text fontSize="16px" fontWeight="bold" color="#fff">
          {popup.name}
        </Text>
        <Text fontSize="14px" mt={2} mb={4}>
          {popup.content}
        </Text>
        <Flex justify="space-between" align="center" mb={4}>
          <Icon as={CalendarIcon} h="30px" w="32px" color="#8C9592" mr={4} />
          <Flex direction="column" flex="1">
            <Text fontWeight="normal" fontSize="14px">
              ระยะเวลา
            </Text>
            <Text fontSize="14px" color="green.500">
              {popup.startDate} - {popup.endDate}
            </Text>
          </Flex>
        </Flex>

        <Box border="1px solid #373F48" borderRadius="6px" px={4} bg="#252d35">
          <Flex
            justify="space-between"
            p={3}
            borderBottom="1px solid #373F48"
            mx={-4}
          >
            <Text fontWeight="normal" fontSize="sm">
              ลำดับ
            </Text>
            <Text fontSize="14px">{popup.sort}</Text>
          </Flex>
          <Flex
            justify="space-between"
            p={3}
            borderBottom="1px solid #373F48"
            mx={-4}
          >
            <Text fontWeight="normal" fontSize="14px">
              อุปกรณ์
            </Text>
            <Text fontSize="14px">{getDeviceText(popup.device)}</Text>
          </Flex>
          <Flex
            justify="space-between"
            p={3}
            borderBottom="1px solid #373F48"
            mx={-4}
          >
            <Text fontWeight="normal" fontSize="14px">
              ภาษา
            </Text>
            <Text fontSize="14px">{popup.language}</Text>
          </Flex>
          <Flex justify="space-between" p={3} mx={-4}>
            <Text fontWeight="normal" fontSize="14px">
              สถานะเปิดใช้งาน
            </Text>
            <Switch isChecked={popup.status === "true"} readOnly />
          </Flex>
        </Box>
      </Box>
      <Flex justify="space-between" align="center">
        <Text fontSize="14px">จัดการป๊อปอัพ</Text>
        <Flex mt={4}>
          <IconButton
            icon={<Icon as={EditIcon} />}
            w={"36px"}
            h={"26px"}
            cursor="pointer"
            color="#fff"
            bg="green.500"
            variant="solid"
            onClick={onEdit}
            mr={2}
          />
          <IconButton
            icon={<Icon as={DeleteIcon} />}
            w={"36px"}
            h={"26px"}
            cursor="pointer"
            variant="solid"
            color="#fff"
            bg="red.500"
            size="sm"
            onClick={onDelete}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default PopupCard;
