import {
  Badge,
  Flex,
  IconButton,
  Link,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { CopyIcon, DashboardIcon, EditIcon } from "components/Icons/Icons";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateformatUtils";

export const marketingSettingColumnsData = (handleModalOpen, link) => [
  {
    Header: "สถานะ",
    accessor: "active",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ cell }) => (
      <Flex align="center">
        <Badge
          bg={cell.value === 1 ? "green.500" : "red.500"}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="normal"
        >
          {cell.value === 1 ? "Active" : "Inactive"}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ชื่อ-นามสกุล",
    accessor: "fullName",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "ยูสเซอร์เนม",
    accessor: "username",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "รหัสผ่าน",
    accessor: "password",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "เบอร์โทรศัพท์",
    accessor: "tel",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "ตำแหน่ง",
    accessor: "role",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "ลิ้งค์",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ row }) => {
      const linkText = `${link}&marketingId=${row.original.id}`;
      const { onCopy } = useClipboard(linkText);

      return (
        <Flex align="center">
          <IconButton
            icon={<CopyIcon />}
            onClick={onCopy}
            aria-label="Copy link"
            variant="outline"
            color="green.500"
            size="sm"
            border="none"
            mr={2}
          />
          <Link href={linkText} color="green.500" isExternal mr={2}>
            <Text isTruncated>{linkText}</Text>
          </Link>
        </Flex>
      );
    },
    disableSortBy: true,
  },
  {
    Header: "Line",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ row }) => {
      const linkText = row.original.lineId;
      const { onCopy } = useClipboard(linkText);

      return (
        <Flex align="center">
          <IconButton
            icon={<CopyIcon />}
            onClick={onCopy}
            aria-label="Copy link"
            variant="outline"
            color="green.500"
            size="sm"
            border="none"
            mr={2}
          />
          <Link href={linkText} color="green.500" isExternal mr={2}>
            <Text isTruncated>{linkText}</Text>
          </Link>
        </Flex>
      );
    },
    disableSortBy: true,
  },
  {
    Header: "วันที่เริ่มทำงาน",
    accessor: "createdDate",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ value }) => formatDate(value),
  },
  {
    Header: "แดชบอร์ดการตลาด",
    Cell: ({ row }) => {
      const history = useHistory();
      return (
        <IconButton
          variant="outline"
          aria-label="dashboard"
          w="40px"
          h="30px"
          size="sm"
          borderColor="rgba(55, 63, 72, 1)"
          bg="rgba(29, 34, 41, 1)"
          icon={<DashboardIcon h="18px" w="18px" color="green.500" />}
          onClick={() => history.push(`/admin/marketing/${row.original.id}`)}
        >
          แก้ไข
        </IconButton>
      );
    },
    disableSortBy: true,
  },
  {
    Header: "แก้ไข",
    accessor: "edit",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        aria-label="edit"
        size="sm"
        borderRadius="28px"
        w="40px"
        h="30px"
        bg="green.500"
        icon={<EditIcon h="18px" w="18px" color="#fff" />}
        onClick={() => handleModalOpen("manageMarketingModal", row.original)}
      >
        แก้ไข
      </IconButton>
    ),
    disableSortBy: true,
  },
];
