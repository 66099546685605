import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useMemberReport } from "hooks/business/member-report/useMemberReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { QueryClientProvider, useQueryClient } from "react-query";
import { memberDepositReportColumnsData } from "variables/columns/member-report/memberDepositReportColumnsData";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";
import { MembersSearchTypes } from "variables/types";

const MemberDepositReport = ({ pageName }) => {
  const queryClient = useQueryClient();

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";
  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const { fetchData, downloadableData } = useMemberReport(
    role,
    MembersSearchTypes.DEPOSIT,
    memberDepositReportColumnsData,
  );

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              color="#fff"
              pb={{ base: "14px", md: "0px" }}
            >
              รายงานสมาชิกที่มีการฝาก
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <QueryClientProvider client={queryClient}>
              <SearchTableRemote
                query={QueryKeys.MEMBER_DEPOSIT_REPORT}
                columnsData={memberDepositReportColumnsData()}
                fetchData={fetchData}
                search={search}
                setSearch={setSearch}
                showExport={true}
                downloadableData={downloadableData}
                filename={pageName}
              />
            </QueryClientProvider>
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default MemberDepositReport;
