import React from "react";
import { animated, useSpring } from "react-spring";

const AnimatedNumber = ({ n, dp, mode, fontSize }) => {
  const { number } = useSpring({ number: n, from: { number: 0 } });

  const fontColor =
    mode === "Withdraw"
      ? "rgba(187, 52, 76, 1)"
      : mode === "Deposit"
      ? "rgba(49, 170, 147, 1)"
      : "#fff";

  return (
    <animated.div style={{ fontSize, color: fontColor, fontWeight: "bold" }}>
      {number.to((n) => Number(n.toFixed(dp)).toLocaleString("en-US"))}
    </animated.div>
  );
};

export default AnimatedNumber;
