import { Badge, Flex, Input, Select, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { getMemberLevelColorScheme } from "utils/CommonUtils";

import { LEVEL_VALIDITY_OPTIONS } from "../../options";

export const memberLevelColumnsData = (handleValueChange) => [
  {
    Header: "ระดับ",
    accessor: "levelName",
    textAlign: "left",
    // width: "135px",
    justifyContent: "flex-start",
    Cell: ({ cell, row }) => (
      <Flex align="center" justify="flex-start">
        <Badge
          bg={getMemberLevelColorScheme(
            (parseInt(row.original.seqLevel) + 1).toString(),
          )}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text fontWeight="normal" color="#fff">
            {cell.value}
          </Text>
        </Badge>
      </Flex>
    ),
    disableSortBy: true,
  },
  {
    Header: "จำนวนการฝาก",
    accessor: "depositCount",
    textAlign: "right",
    justifyContent: "flex-end",
    minWidth: "175px",
    Cell: ({ row }) => {
      const [localValue, setLocalValue] = useState(row.original.depositCount);
      const handleChange = (e) => {
        setLocalValue(e.target.value);
      };

      const handleBlur = () => {
        let val = localValue;
        if (val < 0) val = 0;
        setLocalValue(val);
        handleValueChange(row.original.id, "depositCount", val);
      };

      return (
        <Input
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          textAlign="right"
          borderRadius="6px"
        />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "ยอดฝากขั้นต่ำ",
    accessor: "minTotalDeposit",
    textAlign: "right",
    justifyContent: "flex-end",
    minWidth: "175px",
    Cell: ({ row }) => {
      const [localValue, setLocalValue] = useState(
        row.original.minTotalDeposit.toFixed(2),
      );
      const handleChange = (e) => {
        setLocalValue(e.target.value);
      };

      const handleBlur = () => {
        let val = parseFloat(localValue);
        if (isNaN(val) || val < 0) {
          val = 0;
        }
        setLocalValue(val.toFixed(2));
        handleValueChange(row.original.id, "minTotalDeposit", val);
      };

      return (
        <Input
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          textAlign="right"
          borderRadius="6px"
        />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "เทิรน์โอเวอร์ขั้นต่ำ",
    accessor: "minTotalTurnover",
    textAlign: "right",
    justifyContent: "flex-end",
    minWidth: "175px",
    Cell: ({ row }) => {
      const [localValue, setLocalValue] = useState(
        row.original.minTotalTurnover.toFixed(2),
      );
      const handleChange = (e) => {
        setLocalValue(e.target.value);
      };

      const handleBlur = () => {
        let val = parseFloat(localValue);
        if (isNaN(val) || val < 0) {
          val = 0;
        }
        setLocalValue(val.toFixed(2));
        handleValueChange(row.original.id, "minTotalTurnover", val);
      };

      return (
        <Input
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          textAlign="right"
          borderRadius="6px"
        />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "ระยะเวลา",
    accessor: "validity",
    textAlign: "right",
    justifyContent: "flex-end",
    minWidth: "175px",
    Cell: ({ row, cell: { value } }) => {
      const [localValue, setLocalValue] = useState(value);

      const handleValidityChange = (e) => {
        const newValue = e.target.value;
        setLocalValue(newValue);
        handleValueChange(row.original.id, "validity", newValue);
      };

      return (
        <Select
          value={localValue}
          onChange={handleValidityChange}
          fontSize="14px"
          textAlign="right"
        >
          {LEVEL_VALIDITY_OPTIONS.map((item, index) => (
            <option key={index} value={item.val}>
              {item.text}
            </option>
          ))}
        </Select>
      );
    },
    disableSortBy: true,
  },
];
