import { Switch, Text } from "@chakra-ui/react";

export const gameSettingColumnsData = (handleToggle) => [
  {
    Header: "ผู้ให้บริการ",
    textAlign: "left",
    justifyContent: "flex-start",
    maxWidth: "95px",
    Cell: ({ row }) => {
      const isAmb = row.original.isAmb;
      const provider = row.original.provider;

      let displayText = "AMB";

      if (isAmb === false && provider !== "PGSOFT0" && provider !== "UFA") {
        displayText = "BETFLIX";
      } else if (
        provider === "PGSOFT0" &&
        isAmb === false &&
        provider !== "UFA"
      ) {
        displayText = "NSGSOFT";
      } else if (provider === "UFA") {
        displayText = "UFABET";
      }
      return (
        <Text fontWeight="bold" color="#fff">
          {displayText}
        </Text>
      );
    },
  },
  {
    Header: "เกมส์",
    accessor: "gamename",
    disableSortBy: true,
    textAlign: "left",
    justifyContent: "flex-start",
    maxWidth: "95px",
    Cell: ({ row }) => <Text>{row.original.gamename}</Text>,
  },
  {
    Header: "สถานะ",
    accessor: "isEnable",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ row, value }) => (
      <Switch
        isChecked={value}
        onChange={(e) =>
          handleToggle(
            row.original.provider,
            row.original.type,
            e.target.checked,
          )
        }
      />
    ),
    disableSortBy: true,
  },
];
