import { useQueryClient } from "react-query";
import { APP_PROFILE, APP_ROLES, APP_TOKEN, PREFIX } from "variables/system";

export const useLogout = () => {
  const queryClient = useQueryClient();

  const logout = () => {
    localStorage.removeItem(APP_TOKEN);
    localStorage.removeItem(APP_PROFILE);
    localStorage.removeItem(PREFIX);
    localStorage.removeItem(APP_ROLES);

    queryClient.clear();

    window.location.href = "/auth/login";
  };

  return logout;
};
