import { Button, Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import SimpleTable from "components/Tables/SimpleTable";
import { useMemberLevel } from "hooks/business/member-management/useMemberLevel";
import React from "react";
import { memberLevelColumnsData } from "variables/columns/member-management/memberLevelColumnsData";

const MemberLevel = ({ pageName }) => {
  const {
    memberLevelData,
    isLoading,
    isSaving,
    saveMemberLevel,
    handleValueChange,
  } = useMemberLevel();

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex direction="column">
            <Text fontSize="18px" fontWeight="bold" color="#fff">
              จัดการระดับสมาชิก
            </Text>
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <SimpleTable
              tableData={memberLevelData}
              columnsData={memberLevelColumnsData(handleValueChange)}
              isLoading={isLoading}
            />
          </CardBody>
        </Animated>
        <Flex justify="flex-start" mt={4}>
          <Button
            onClick={saveMemberLevel}
            background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "195px" }}
            fontWeight="normal"
            fontSize="14px"
            isLoading={isSaving}
          >
            บันทึกข้อมูล
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
};

export default MemberLevel;
