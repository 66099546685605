import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Switch,
  Text,
  Textarea,
  useBreakpointValue,
} from "@chakra-ui/react";
import CardFooter from "components/Card/CardFooter";
import { DeleteIcon } from "components/Icons/Icons";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { Field, FieldArray, Form, Formik } from "formik";
import { useMemberLevel } from "hooks/business/member-management/useMemberLevel";
import { useGameSetting } from "hooks/business/system-setting/useGameSetting";
import React, { useEffect, useState } from "react";
import {
  BONUS_OPTIONS,
  GAME_OPTIONS,
  PROMOTION_OPTIONS,
  TURNOVER_OPTIONS,
} from "variables/options";
import { GameTypes, PromotionTypes } from "variables/types";

const ManagePromotionModal = ({
  isOpen,
  onClose,
  promotion = null,
  handleSubmit,
  handleToggle,
}) => {
  const [filteredProviders, setFilteredProviders] = useState([]);
  const { memberLevelData } = useMemberLevel();
  const {
    slotProviderData,
    casinoProviderData,
    sportProviderData,
    cardProviderData,
  } = useGameSetting();

  const fetchProvidersByType = (type) => {
    switch (type) {
      case GameTypes.ALL:
        setFilteredProviders([
          ...slotProviderData,
          ...casinoProviderData,
          ...sportProviderData,
          ...cardProviderData,
        ]);
        return;
      case GameTypes.SPORT:
        setFilteredProviders(sportProviderData);
        return;
      case GameTypes.SLOT:
        setFilteredProviders(slotProviderData);
        return;
      case GameTypes.LIVE:
        setFilteredProviders(casinoProviderData);
        return;
      case GameTypes.CARD:
        setFilteredProviders(cardProviderData);
        return;
    }
  };

  useEffect(() => {
    if (promotion && promotion.typeGame) {
      fetchProvidersByType(promotion.typeGame);
    }
  }, [
    promotion?.typeGame,
    slotProviderData,
    casinoProviderData,
    sportProviderData,
    cardProviderData,
  ]);

  const modalSize = useBreakpointValue({ base: "xl", md: "4xl" });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize} isCentered>
      <ModalOverlay />
      <ModalContent maxH="95vh">
        <ModalHeader>เพิ่ม / แก้ไข โปรโมชั่น</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              urlImage: promotion?.urlImage || "",
              name: promotion?.name || "",
              typePromotion: promotion?.typePromotion || "",
              typeGame: promotion?.typeGame || "",
              typeBonus: promotion?.typeBonus || "",
              typeTurn: promotion?.typeTurn || "",
              maxBonus: promotion?.maxBonus || "",
              turnOver: promotion?.turnOver || "",
              maxWithdraw: promotion?.maxWithdraw || "",
              active: promotion?.active || false,
              description: promotion?.description || "",
              conditions: promotion?.conditions || [
                { minTopup: "", maxTopup: "", bonus: "" },
              ],
              maxReceiveBonus: promotion?.maxReceiveBonus || "",
              startTime: promotion?.startTime || "",
              endTime: promotion?.endTime || "",
              orderPromotion: promotion?.orderPromotion || "",
              memberLevelId: promotion?.memberLevelId || "",
              minTopup: promotion?.minTopup || "",
              maxTopup: promotion?.maxTopup || "",
              gameBlockList: promotion?.gameBlockList || [],
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, promotion, actions, onClose);
            }}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue, isSubmitting }) => (
              <Form>
                <Grid
                  templateColumns={{
                    base: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                  }}
                  gap={4}
                  p={0}
                  m={0}
                  pt={4}
                >
                  <GridItem
                    colSpan={{ base: 2, md: 1 }}
                    display={{ base: "flex", md: "none" }}
                    justifyContent="left"
                    alignItems="flex-end"
                  >
                    <Flex align="center">
                      <Switch
                        isChecked={values.active}
                        onChange={(e) =>
                          setFieldValue("active", e.target.checked)
                        }
                      />
                      <FormLabel ml={2}>Active</FormLabel>
                    </Flex>
                  </GridItem>
                  <GridItem p={0} m={0} colSpan={{ base: 2, md: 3 }}>
                    <ImageUploader
                      initialImage={values.urlImage}
                      onUpload={(uri) => setFieldValue("urlImage", uri)}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 2 }}>
                    <FormControl>
                      <FormLabel>ชื่อโปรโมชั่น</FormLabel>
                      <Input
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        fontSize="sm"
                        placeholder="ระบุชื่อโปรโมชั่น"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem
                    colSpan={{ base: 2, md: 1 }}
                    display={{ base: "none", md: "flex" }}
                    justifyContent="right"
                    alignItems="flex-end"
                  >
                    <Flex align="center">
                      <Switch
                        isChecked={values.active}
                        onChange={(e) =>
                          setFieldValue("active", e.target.checked)
                        }
                      />
                      <FormLabel ml={2}>Active</FormLabel>
                    </Flex>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 3 }}>
                    <FormControl>
                      <FormLabel>ประเภทเกมส์</FormLabel>
                      <Select
                        borderRadius="33px"
                        name="typeGame"
                        onChange={(e) => {
                          handleChange(e);
                          fetchProvidersByType(e.target.value);
                        }}
                        fontSize="sm"
                        value={values.typeGame}
                      >
                        {GAME_OPTIONS.map((type, id) => (
                          <option key={id} value={type.typeCode}>
                            {type.typeName}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    {filteredProviders.length > 0 && (
                      <Box
                        p={3}
                        bg="#1E252C"
                        mt={4}
                        borderRadius="10px"
                        border="1px solid #373F48"
                      >
                        <Box maxHeight="165px" overflowY="auto">
                          <Grid
                            templateColumns={{
                              base: "repeat(1, 1fr)",
                              md: "repeat(3, 1fr)",
                            }}
                            gap={1}
                          >
                            {filteredProviders.map((provider) => {
                              const isDisabled = values.gameBlockList.some(
                                (item) => item.provider === provider.provider,
                              );

                              return (
                                <GridItem key={provider.id}>
                                  <Flex align="center">
                                    <Switch
                                      isChecked={!isDisabled}
                                      onChange={() =>
                                        handleToggle(
                                          provider.id,
                                          provider.provider,
                                          setFieldValue,
                                          values,
                                        )
                                      }
                                      colorScheme={isDisabled ? "red" : "green"}
                                    ></Switch>
                                    <Text
                                      fontSize="sm"
                                      mb={2}
                                      textAlign="center"
                                      ml={2}
                                    >
                                      {provider.gamename}
                                    </Text>
                                  </Flex>
                                </GridItem>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>ประเภทโปรโมชั่น</FormLabel>
                      <Select
                        borderRadius="33px"
                        name="typePromotion"
                        onChange={handleChange}
                        value={values.typePromotion}
                        placeholder="กรุณาเลือกประเภท"
                        fontSize="sm"
                      >
                        {PROMOTION_OPTIONS.map((type, id) => (
                          <option key={id} value={type.typeCode}>
                            {type.typeName}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>ประเภทโบนัส</FormLabel>
                      <Select
                        borderRadius="33px"
                        name="typeBonus"
                        onChange={handleChange}
                        value={values.typeBonus}
                        placeholder="กรุณาเลือกประเภท"
                        fontSize="sm"
                      >
                        {BONUS_OPTIONS.map((type, id) => (
                          <option key={id} value={type.typeCode}>
                            {type.typeName}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>ประเภทเทิร์น</FormLabel>
                      <Select
                        borderRadius="33px"
                        name="typeTurn"
                        onChange={handleChange}
                        fontSize="sm"
                        value={values.typeTurn}
                        placeholder="กรุณาเลือกประเภท"
                      >
                        {TURNOVER_OPTIONS.map((type, id) => (
                          <option key={id} value={type.typeCode}>
                            {type.typeName}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  {(values.typePromotion === PromotionTypes.ALL_DAY ||
                    values.typePromotion === PromotionTypes.GOLDEN_TIME) && (
                    <GridItem colSpan={3}>
                      <FormControl>
                        <FormLabel>จำนวนครั้งในการรับโปรโมชั่นต่อวัน</FormLabel>
                        <NumberInput
                          value={values.maxReceiveBonus}
                          onChange={(valueString) =>
                            setFieldValue("maxReceiveBonus", valueString)
                          }
                        >
                          <NumberInputField
                            name="maxReceiveBonus"
                            placeholder="1000"
                            fontSize="sm"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    </GridItem>
                  )}
                  {values.typePromotion === PromotionTypes.GOLDEN_TIME && (
                    <>
                      <GridItem>
                        <FormControl>
                          <FormLabel>เวลาเริ่มต้น</FormLabel>
                          <Input
                            name="startTime"
                            size="md"
                            type="time"
                            value={values.startTime}
                            onChange={(e) =>
                              setFieldValue("startTime", e.target.value)
                            }
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>เวลาเริ่มสิ้นสุด</FormLabel>
                          <Input
                            name="endTime"
                            size="md"
                            type="time"
                            value={values.endTime}
                            onChange={(e) =>
                              setFieldValue("endTime", e.target.value)
                            }
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                    </>
                  )}
                  <GridItem>
                    <FormControl>
                      <FormLabel>โบนัสสูงสุด</FormLabel>
                      <NumberInput
                        value={values.maxBonus}
                        onChange={(valueString) =>
                          setFieldValue("maxBonus", valueString)
                        }
                      >
                        <NumberInputField
                          name="maxBonus"
                          placeholder="1000"
                          fontSize="sm"
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>ฝากต่ำสุด</FormLabel>
                      <NumberInput
                        value={values.minTopup}
                        onChange={(valueString) =>
                          setFieldValue("minTopup", valueString)
                        }
                      >
                        <NumberInputField
                          name="minTopup"
                          placeholder="1000"
                          fontSize="sm"
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>ฝากสูงสุด</FormLabel>
                      <NumberInput
                        value={values.maxTopup}
                        onChange={(valueString) =>
                          setFieldValue("maxTopup", valueString)
                        }
                      >
                        <NumberInputField
                          name="maxTopup"
                          placeholder="1000"
                          fontSize="sm"
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>เทิร์์นโอเวอร์</FormLabel>
                      <NumberInput
                        value={values.turnOver}
                        onChange={(valueString) =>
                          setFieldValue("turnOver", valueString)
                        }
                      >
                        <NumberInputField
                          name="turnOver"
                          placeholder="1"
                          fontSize="sm"
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>เรียงลำดับ</FormLabel>
                      <NumberInput
                        value={values.orderPromotion}
                        onChange={(valueString) =>
                          setFieldValue("orderPromotion", valueString)
                        }
                      >
                        <NumberInputField
                          name="orderPromotion"
                          placeholder="1"
                          fontSize="sm"
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>จำนวนยอดถอนสูงสุดกี่เท่า</FormLabel>
                      <NumberInput
                        value={values.maxWithdraw}
                        onChange={(valueString) =>
                          setFieldValue("maxWithdraw", valueString)
                        }
                      >
                        <NumberInputField
                          name="maxWithdraw"
                          placeholder="10000"
                          fontSize="sm"
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Box mt={4}>
                  <FormControl>
                    <FormLabel>ระดับสมาชิก</FormLabel>
                    <Select
                      borderRadius="33px"
                      fontSize="sm"
                      name="memberLevelId"
                      onChange={handleChange}
                      placeholder="ทั้งหมด"
                      value={values.memberLevelId}
                    >
                      {memberLevelData?.map((level) => (
                        <option key={level.id} value={level.id}>
                          {level.levelName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <GridItem mt={4}>
                  <FormControl>
                    <FormLabel>รายละเอียด</FormLabel>
                    <Field
                      as={Textarea}
                      name="description"
                      minHeight="125px"
                      fontSize="sm"
                      bg="#1E252C"
                      placeholder="รายละเอียด..."
                    />
                  </FormControl>
                </GridItem>
                <FieldArray name="conditions">
                  {({ push, remove }) => (
                    <>
                      <Flex
                        justify="space-between"
                        align="center"
                        mt={4}
                        mb={4}
                      >
                        <Text fontSize="16px" color="#fff" fontWeight="bold">
                          เงื่อนไข
                        </Text>
                        <Button
                          padding={3}
                          variant="outline"
                          borderColor={"green.500"}
                          color={"#fff"}
                          bg={"#1E252C"}
                          fontSize="14px"
                          fontWeight="normal"
                          width="auto"
                          borderRadius="34px"
                          onClick={() =>
                            push({
                              minTopup: "",
                              maxTopup: "",
                              bonus: "",
                            })
                          }
                        >
                          เพิ่มเงื่อนไข
                        </Button>
                      </Flex>
                      <Divider my={4} borderColor="#ffffff14" />
                      <Grid
                        templateColumns={{
                          base: "repeat(3, 1fr) auto",
                          md: "repeat(3, 1fr) auto",
                        }}
                        gap="10px"
                      >
                        <GridItem colSpan={4}>
                          <Grid
                            templateColumns={{
                              base: "repeat(2, 1fr) auto",
                              md: "repeat(3, 1fr) auto",
                            }}
                            pr={{ base: 24, md: 8 }}
                          >
                            <GridItem display="flex" alignItems="left">
                              <Text>ต่ำสุด</Text>
                            </GridItem>
                            <GridItem display="flex" alignItems="left">
                              <Text>สูงสุด</Text>
                            </GridItem>
                            <GridItem display="flex" alignItems="left">
                              <Text>โบนัส</Text>
                            </GridItem>
                            <GridItem />
                          </Grid>
                        </GridItem>
                        {values.conditions.map((condition, index) => (
                          <React.Fragment key={index}>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <NumberInput
                                  value={condition.minTopup}
                                  onChange={(valueString) =>
                                    setFieldValue(
                                      `conditions.${index}.minTopup`,
                                      valueString,
                                    )
                                  }
                                >
                                  <NumberInputField
                                    name={`conditions.${index}.minTopup`}
                                    placeholder="eg. 10000"
                                    fontSize="sm"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <NumberInput
                                  value={condition.maxTopup}
                                  onChange={(valueString) =>
                                    setFieldValue(
                                      `conditions.${index}.maxTopup`,
                                      valueString,
                                    )
                                  }
                                >
                                  <NumberInputField
                                    name={`conditions.${index}.maxTopup`}
                                    placeholder="eg. 1000000"
                                    fontSize="sm"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <NumberInput
                                  value={condition.bonus}
                                  onChange={(valueString) =>
                                    setFieldValue(
                                      `conditions.${index}.bonus`,
                                      valueString,
                                    )
                                  }
                                >
                                  <NumberInputField
                                    name={`conditions.${index}.bonus`}
                                    placeholder="eg. 1"
                                    fontSize="sm"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </FormControl>
                            </GridItem>
                            <GridItem
                              colSpan={1}
                              display="flex"
                              justifyContent="center"
                              alignItems="flex-end"
                            >
                              {index > 0 ? (
                                <IconButton
                                  variant="outline"
                                  borderColor={"red.500"}
                                  color={"red.500"}
                                  aria-label="delete"
                                  size="md"
                                  icon={<DeleteIcon />}
                                  bg="#1E252C"
                                  onClick={() => remove(index)}
                                />
                              ) : (
                                <IconButton variant="ghost" disabled />
                              )}
                            </GridItem>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </>
                  )}
                </FieldArray>
                <CardFooter pl={0} pr={0} mt={8} pb={6} justifyContent="center">
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    type="submit"
                    w="100%"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    fontSize="14px"
                    fontWeight="normal"
                  >
                    ทำรายการ
                  </Button>
                </CardFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManagePromotionModal;
