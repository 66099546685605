import { Button, Flex, Stack, Switch, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import SimpleTable from "components/Tables/SimpleTable";
import { useJackpotSetting } from "hooks/business/system-setting/useJackpotSetting";
import React, { useState } from "react";
import { jackpotSettingColumnsData } from "variables/columns/system-setting/jackpotSettingColumnsData";

import JackpotBotSettingModal from "./components/JackpotBotSettingModal";
import ManageJackpotModal from "./components/ManageJackpotModal";

const JackpotSetting = ({ pageName }) => {
  const [selectedJackpot, setSelectedJackpot] = useState(null);
  const {
    jackpotData,
    verifySuccessMessage,
    verifyUnSuccessMessage,
    isUserVerified,
    isLoading,
    isActive,
    isSaving,
    handleValueChange,
    handleSave,
    handleSwitchChange,
    handleSaveJackpotBotSetting,
    handleGiveJackpot,
    handleVerifyJackpot,
  } = useJackpotSetting();

  const [modalState, setModalState] = useState({
    manageJackpotModalOpen: false,
    jackpotBotSettingModalOpen: false,
  });

  const handleModalOpen = (modal, jackpot = null) => {
    setSelectedJackpot(jackpot);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedJackpot(null);
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="15px" w="100%">
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <PageHeader pageName={pageName} />
        </Animated>
        <Card>
          <CardHeader>
            <Text color="white" fontSize="18px" fontWeight="bold" pb="12px">
              รางวัลแจ๊คพ๊อต
            </Text>
          </CardHeader>
          <Flex pb="24px">
            <Switch
              colorScheme="green"
              isChecked={isActive}
              onChange={handleSwitchChange}
            />
            <Text mb="2px" ml={6}>
              เปิด/ปิด รางวัลแจ็คพอต
            </Text>
          </Flex>
          <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
            <CardBody>
              <SimpleTable
                tableData={jackpotData}
                columnsData={jackpotSettingColumnsData(
                  handleModalOpen,
                  handleValueChange,
                )}
                isLoading={isLoading}
              />
            </CardBody>
          </Animated>
          <Flex justify="flex-start" duration={0.4} delay={0.8} pt="15px">
            <Button
              width={{ base: "100%", md: "auto" }}
              minW={{ base: "none", md: "150px" }}
              background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              borderRadius="33px"
              fontSize="14px"
              fontWeight="normal"
              onClick={handleSave}
              isLoading={isSaving}
            >
              บันทึกข้อมูล
            </Button>
          </Flex>
        </Card>
        <ManageJackpotModal
          isOpen={modalState.manageJackpotModalOpen}
          onClose={() => handleModalClose("manageJackpotModal")}
          jackpot={selectedJackpot}
          handleSubmit={handleGiveJackpot}
          handleVerify={handleVerifyJackpot}
          isUserVerified={isUserVerified}
          verifySuccessMessage={verifySuccessMessage}
          verifyUnSuccessMessage={verifyUnSuccessMessage}
        />
        <JackpotBotSettingModal
          isOpen={modalState.jackpotBotSettingModalOpen}
          onClose={() => handleModalClose("jackpotBotSettingModal")}
          jackpot={selectedJackpot}
          jackpotData={jackpotData}
          isActive={isActive}
          handleSubmit={handleSaveJackpotBotSetting}
        />
      </Stack>
    </Flex>
  );
};

export default JackpotSetting;
