import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import { Form, Formik } from "formik";
import { useConditionSetting } from "hooks/business/system-setting/useConditionSetting";
import React from "react";
import { Element } from "react-scroll";
import { REFUND_TYPE_OPTIONS } from "variables/options";

const ConditionSetting = ({ pageName }) => {
  const {
    conditionData,
    showInputField2,
    setShowInputField2,
    showColumn2Inputs,
    setShowColumn2Inputs,
    showColumn3Input,
    setShowColumn3Input,
    handleSubmit,
    isSaving,
  } = useConditionSetting();

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card>
        <CardHeader pb={6}>
          <Text fontSize="18px" fontWeight="bold" color="#fff">
            จัดการตั้งค่าเงื่อนไขเว็บไซต์
          </Text>
        </CardHeader>
        <Formik
          initialValues={{
            prefix: conditionData?.prefix || "",
            website: conditionData?.website || "",
            runningText: conditionData?.runningText || "",
            lineId: conditionData?.lineId || "",
            lineToken: conditionData?.lineToken || "",
            lineTokenWithdraw: conditionData?.lineTokenWithdraw || "",
            telegramToken: conditionData?.telegramToken || "",
            telegramDeposit: conditionData?.telegramDeposit || "",
            telegramWithdraw: conditionData?.telegramWithdraw || "",
            config: conditionData?.config || {
              allowOutstandingBet: "false",
              approveWithdrawAuto: false,
              limitWithDraw: false,
              winLoseRefund: true,
              winLoseRefundMax: "0",
              onOffWebsite: true,
              minWithdrawCredit: "1",
              forceWithdrawAll: false,
              countWithDraw: "1000",
              winLoseRefundTurn: "0",
              winLoseRefundType: "WEEKLY",
              checkBonusWithdraw: false,
              maxAutoWithdraw: 20,
              winLoseRefundRate: 5,
              approveWithdrawAutoNew: true,
              checkAcctFlag: false,
            },
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <Stack direction="column" spacing="15px" w="100%">
                  <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      p={0}
                      boxShadow="none"
                      borderBottom="1px solid #373F48"
                      borderRadius="0px"
                      pb="48px"
                    >
                      <Element id="info" name="info">
                        <CardBody>
                          <Grid
                            templateColumns={{
                              base: "repeat(1, 1fr)",
                              md: "repeat(3, 1fr)",
                            }}
                            gap="20px"
                            w="100%"
                          >
                            <GridItem colSpan={3}>
                              <Flex>
                                <Switch
                                  isChecked={values.config.onOffWebsite}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "config.onOffWebsite",
                                      e.target.checked,
                                    )
                                  }
                                />
                                <Text
                                  fontWeight="normal"
                                  fontSize="16px"
                                  ml={4}
                                >
                                  เปิด/ปิด เว็บไซต์
                                </Text>
                              </Flex>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                              <FormControl>
                                <FormLabel>Prefix</FormLabel>
                                <Input
                                  placeholder="eg. Michael"
                                  fontSize="sm"
                                  value={values.prefix}
                                  readOnly
                                  backgroundColor="#373F48"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                              <FormControl>
                                <FormLabel>ลิ้งค์เว็บไซต์</FormLabel>
                                <Input
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.website}
                                  onChange={handleChange}
                                  name="website"
                                  readOnly
                                  backgroundColor="#373F48"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                              <FormControl>
                                <FormLabel>ไลน์ OA</FormLabel>
                                <Input
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.lineId}
                                  onChange={handleChange}
                                  name="lineId"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                              <FormControl>
                                <FormLabel>อักษรวิ่งหน้าเว็บ</FormLabel>
                                <Input
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.runningText}
                                  onChange={handleChange}
                                  name="runningText"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  Line แจ้งเตือนฝาก{" "}
                                  <Box
                                    as="span"
                                    color="green.500"
                                    fontWeight="normal"
                                  >
                                    (ใส่ Token)
                                  </Box>
                                </FormLabel>
                                <Input
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.lineToken}
                                  onChange={handleChange}
                                  name="lineToken"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  Line แจ้งเตือนถอน{" "}
                                  <Box
                                    as="span"
                                    color="green.500"
                                    fontWeight="normal"
                                  >
                                    (ใส่ Token)
                                  </Box>
                                </FormLabel>
                                <Input
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.lineTokenWithdraw}
                                  onChange={handleChange}
                                  name="lineTokenWithdraw"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                              <FormControl>
                                <FormLabel>Telegram Token</FormLabel>
                                <Input
                                  placeholder="eg. Michael"
                                  fontSize="sm"
                                  value={values.telegramToken}
                                  onChange={handleChange}
                                  name="telegramToken"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  ID กลุ่ม Telegram แจ้งเตือนฝาก
                                </FormLabel>
                                <Input
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.telegramDeposit}
                                  onChange={handleChange}
                                  name="telegramDeposit"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={{ base: 3, md: 1 }}>
                              <FormControl>
                                <FormLabel>
                                  ID กลุ่ม Telegram แจ้งเตือนถอน
                                </FormLabel>
                                <Input
                                  placeholder="eg. Jackson"
                                  fontSize="sm"
                                  value={values.telegramWithdraw}
                                  onChange={handleChange}
                                  name="telegramWithdraw"
                                />
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Element>
                    </Card>
                  </Animated>
                  <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      p={0}
                      boxShadow="none"
                      borderBottom="1px solid #373F48"
                      borderRadius="0px"
                      pb="48px"
                    >
                      <Grid
                        templateColumns={{
                          base: "repeat(1, 1fr)",
                          md: "repeat(3, 1fr)",
                        }}
                        gap="20px"
                        w="100%"
                      >
                        <GridItem colSpan={{ base: 3, md: 1 }}>
                          <FormLabel>
                            จำนวนเครดิตขั้นต่ำที่สามารถถอนได้
                          </FormLabel>
                          <NumberInput
                            value={values.config.minWithdrawCredit}
                            onChange={(valueString) =>
                              setFieldValue(
                                "config.minWithdrawCredit",
                                valueString,
                              )
                            }
                            min={0}
                          >
                            <NumberInputField
                              placeholder="0.00"
                              fontSize="sm"
                            />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </GridItem>
                        <GridItem
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          colSpan={{ base: 3, md: 1 }}
                        >
                          <FormControl display="flex">
                            <FormLabel>อนุมัติรายการถอนแบบออโต้</FormLabel>
                            <Switch
                              isChecked={values.config.approveWithdrawAuto}
                              onChange={(e) => {
                                setFieldValue(
                                  "config.approveWithdrawAuto",
                                  e.target.checked,
                                );
                                setShowInputField2(e.target.checked);
                              }}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          colSpan={{ base: 3, md: 1 }}
                        >
                          <FormControl display="flex">
                            <FormLabel>บังคับถอนทั้งหมด</FormLabel>
                            <Switch
                              isChecked={values.config.forceWithdrawAll}
                              onChange={(e) =>
                                setFieldValue(
                                  "config.forceWithdrawAll",
                                  e.target.checked,
                                )
                              }
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={{ base: 3, md: 1 }}>
                          {showInputField2 && (
                            <FormControl>
                              <FormLabel>
                                จำนวนเงินมากที่สุดที่จะไม่ทำการอนุมัติออโต้
                              </FormLabel>
                              <NumberInput
                                value={values.config.maxAutoWithdraw}
                                onChange={(valueString) =>
                                  setFieldValue(
                                    "config.maxAutoWithdraw",
                                    valueString,
                                  )
                                }
                                min={0}
                              >
                                <NumberInputField
                                  placeholder="0.00"
                                  fontSize="sm"
                                />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </FormControl>
                          )}
                        </GridItem>
                        <GridItem
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          colSpan={{ base: 3, md: 1 }}
                        >
                          <FormControl display="flex">
                            <FormLabel>
                              ดึงชื่อบัญชีธนาคารอัตโนมัติตอนสมัคร
                            </FormLabel>
                            <Switch
                              isChecked={values.config.checkAcctFlag}
                              onChange={(e) =>
                                setFieldValue(
                                  "config.checkAcctFlag",
                                  e.target.checked,
                                )
                              }
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          colSpan={{ base: 3, md: 1 }}
                        >
                          <FormControl display="flex">
                            <FormLabel>ต้องอนุมัติหากรับโปรโมชั่น</FormLabel>
                            <Switch
                              isChecked={values.config.checkBonusWithdraw}
                              onChange={(e) =>
                                setFieldValue(
                                  "config.checkBonusWithdraw",
                                  e.target.checked,
                                )
                              }
                            />
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </Card>
                  </Animated>
                  <Animated animation="fadeInUp" duration="0.9s" delay="0.1s">
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                      p={0}
                      boxShadow="none"
                      borderRadius="0px"
                    >
                      <Element id="config" name="config">
                        <CardBody>
                          <Grid
                            templateColumns={{
                              base: "repeat(1, 1fr)",
                              md: "repeat(2, 1fr)",
                            }}
                            gap="20px"
                            width="100%"
                          >
                            <GridItem>
                              <Box>
                                <FormControl mb={4} display="flex">
                                  <FormLabel>รอบการคืนยอดเสีย</FormLabel>
                                  <Switch
                                    isChecked={values.config.winLoseRefund}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "config.winLoseRefund",
                                        e.target.checked,
                                      );
                                      setShowColumn2Inputs(e.target.checked);
                                    }}
                                  />
                                </FormControl>
                                {showColumn2Inputs && (
                                  <>
                                    <FormControl mb={4}>
                                      <FormLabel
                                        fontWeight="normal"
                                        fontSize="16px"
                                      >
                                        ประเภทการคืนยอดเสีย
                                      </FormLabel>
                                      <Select
                                        fontSize="sm"
                                        value={values.config.winLoseRefundType}
                                        borderRadius="33px"
                                        onChange={(e) =>
                                          setFieldValue(
                                            "config.winLoseRefundType",
                                            e.target.value,
                                          )
                                        }
                                      >
                                        {REFUND_TYPE_OPTIONS.map((type, id) => (
                                          <option
                                            key={id}
                                            value={type.typeCode}
                                          >
                                            {type.typeName}
                                          </option>
                                        ))}
                                      </Select>
                                    </FormControl>

                                    <FormControl mb={4}>
                                      <FormLabel>
                                        {" "}
                                        เปอร์เซ็นการคืนยอดเสีย{" "}
                                        <Box
                                          as="span"
                                          color="green.500"
                                          fontWeight="normal"
                                        >
                                          (%)
                                        </Box>
                                      </FormLabel>
                                      <NumberInput
                                        value={values.config.winLoseRefundRate}
                                        onChange={(valueString) =>
                                          setFieldValue(
                                            "config.winLoseRefundRate",
                                            valueString,
                                          )
                                        }
                                        min={0}
                                      >
                                        <NumberInputField
                                          placeholder="5%"
                                          fontSize="sm"
                                        />
                                        <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                        </NumberInputStepper>
                                      </NumberInput>
                                    </FormControl>

                                    <FormControl mb={4}>
                                      <FormLabel
                                        fontWeight="normal"
                                        fontSize="16px"
                                      >
                                        เทิร์นการคืนยอดเสีย{" "}
                                        <Box
                                          as="span"
                                          color="green.500"
                                          fontWeight="normal"
                                        >
                                          (คูณกี่เท่า)
                                        </Box>
                                      </FormLabel>
                                      <NumberInput
                                        value={values.config.winLoseRefundTurn}
                                        onChange={(valueString) =>
                                          setFieldValue(
                                            "config.winLoseRefundTurn",
                                            valueString,
                                          )
                                        }
                                        min={0}
                                      >
                                        <NumberInputField
                                          placeholder="5"
                                          fontSize="sm"
                                        />
                                        <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                        </NumberInputStepper>
                                      </NumberInput>
                                    </FormControl>
                                    <FormControl>
                                      <FormLabel
                                        fontWeight="normal"
                                        fontSize="16px"
                                      >
                                        การได้คืนยอดเสียสูงสุด{" "}
                                        <Box
                                          as="span"
                                          color="green.500"
                                          fontWeight="normal"
                                        >
                                          (บาท)
                                        </Box>
                                      </FormLabel>
                                      <NumberInput
                                        value={values.config.winLoseRefundMax}
                                        onChange={(valueString) =>
                                          setFieldValue(
                                            "config.winLoseRefundMax",
                                            valueString,
                                          )
                                        }
                                        min={0}
                                      >
                                        <NumberInputField
                                          placeholder="0.00"
                                          fontSize="sm"
                                        />
                                        <NumberInputStepper>
                                          <NumberIncrementStepper />
                                          <NumberDecrementStepper />
                                        </NumberInputStepper>
                                      </NumberInput>
                                    </FormControl>
                                  </>
                                )}
                              </Box>
                            </GridItem>
                            <GridItem>
                              <Box>
                                <FormControl mb={4} display="flex">
                                  <FormLabel>
                                    จำกัดจำนวนครั้งการถอนต่อวัน
                                  </FormLabel>
                                  <Switch
                                    isChecked={values.config.limitWithDraw}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "config.limitWithDraw",
                                        e.target.checked,
                                      );
                                      setShowColumn3Input(e.target.checked);
                                    }}
                                  />
                                </FormControl>
                                {showColumn3Input && (
                                  <FormControl mb={4}>
                                    <FormLabel>
                                      ถอนได้กี่ครั้งต่อวัน{" "}
                                      <Box
                                        as="span"
                                        color="green.500"
                                        fontWeight="normal"
                                      >
                                        (ตัดรอบ 23.00 ของทุกวัน)
                                      </Box>
                                    </FormLabel>
                                    <NumberInput
                                      value={values.config.countWithDraw}
                                      onChange={(valueString) =>
                                        setFieldValue(
                                          "config.countWithDraw",
                                          valueString,
                                        )
                                      }
                                      min={0}
                                    >
                                      <NumberInputField
                                        placeholder="10"
                                        fontSize="sm"
                                      />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                  </FormControl>
                                )}
                              </Box>
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Element>
                    </Card>
                  </Animated>
                  <Animated
                    justify="flex-start"
                    pt="15px"
                    pb="35px"
                    duration="0.6s"
                    delay="0.1s"
                  >
                    <Button
                      width={{ base: "100%", md: "auto" }}
                      minW={{ base: "none", md: "150px" }}
                      background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                      borderRadius="33px"
                      type="submit"
                      fontSize="14px"
                      fontWeight="normal"
                      isLoading={isSaving}
                    >
                      บันทึกข้อมูล
                    </Button>
                  </Animated>
                </Stack>
              </Flex>
            </Form>
          )}
        </Formik>
      </Card>
    </Flex>
  );
};

export default ConditionSetting;
