export const createLineChartOptions = ({
  xAxisCategories = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  lineColors = ["#31AA93", "#BB344C", "#FF5733"],
  fillColors = [
    "rgba(49, 170, 147, 0.2)",
    "rgba(187, 52, 76, 0.2)",
    "rgba(255, 87, 51, 0.2)",
  ], // Custom fill colors

  showLegend = false,
} = {}) => {
  return {
    chart: {
      toolbar: {
        show: false,
      },
      type: "line",
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val === 0 ? "" : val;
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: xAxisCategories,
      labels: {
        style: {
          colors: "#8C9592",
          fontSize: "10px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#8C9592",
          fontSize: "10px",
        },
      },
    },
    legend: {
      show: showLegend,
    },
    gradient: false,
    grid: {
      borderColor: "rgb(49,59,69)",
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 0,
      colors: lineColors,
      strokeColors: lineColors,
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    fill: {
      type: "solid",
      colors: fillColors,
      opacity: [0.2, 0.2, 0.2],
    },
    colors: lineColors,
  };
};
