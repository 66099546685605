import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addPopup,
  deletePopup,
  fetchPopup,
  updatePopup,
} from "services/website-setting/popupService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const usePopupSetting = () => {
  const queryClient = useQueryClient();
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const { data: popupData, isLoading } = useQuery(
    QueryKeys.POPUP_SETTING,
    async () => {
      const response = await fetchPopup();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const deleteMutation = useMutation(async (id) => await deletePopup(id), {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries([QueryKeys.POPUP_SETTING]);
    },
    onError: (error) => handleError(error, ERROR_MESSAGE),
  });

  const submitMutation = useMutation(
    async ({ values, popup }) => {
      return popup ? updatePopup({ ...popup, ...values }) : addPopup(values);
    },
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.POPUP_SETTING]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const handleDeletePopup = (id) => {
    deleteMutation.mutate(id);
  };

  const handleSubmitPopup = (values, popup, actions) => {
    submitMutation.mutate(
      { values, popup },
      {
        onSettled: () => actions.setSubmitting(false),
      },
    );
  };

  return {
    popupData,
    isLoading,
    handleDeletePopup,
    handleSubmitPopup,
    isDeleting: deleteMutation.isLoading,
  };
};
