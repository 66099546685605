import { Flex, Text } from "@chakra-ui/react";
import "assets/css/styles.css";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useDepositTransaction } from "hooks/business/transaction-report/useDepositTransaction";
import { useSearch } from "hooks/common/useSearch";
import React, { useState } from "react";
import { QueryClientProvider, useQueryClient } from "react-query";
import { depositTransactionReportColumnsData } from "variables/columns/transaction-report/depositTransactionReportColumnsData";
import { DEPOSIT_TRANSACTION_SEARCH_OPTIONS } from "variables/options";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";
import { DepositTransactionTypes } from "variables/types";

import ApproveInvalidTransactionModal from "../invalid/components/ApproveInvalidTransactionModal";
import RejectInvalidTransactionModal from "../invalid/components/RejectInvalidTransactionModal";
import ApproveBlockAutoTransactionModal from "./components/ApproveBlockAutoTransactionModal";
import QrCodeModal from "./components/QrCodeModal";
import RejectBlockAutoTransactionModal from "./components/RejectBlockAutoTransactionModal";

const DepositTransactionReport = ({ pageName }) => {
  const queryClient = useQueryClient();
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const {
    fetchData,
    downloadableData,
    handleSaveTransaction,
    handleInvalidTransaction,
  } = useDepositTransaction(
    role,
    QueryKeys.DEPOSIT_TRANSACTION_REPORT,
    depositTransactionReportColumnsData,
    DepositTransactionTypes.ALL,
  );

  const [modalState, setModalState] = useState({
    approveBlockAutoTransactionModalOpen: false,
    rejectBlockAutoTransactionModalOpen: false,
    approveInvalidTransactionModalOpen: false,
    rejectInvalidTransactionModalOpen: false,
    qrCodeModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      status: selectedOption ? selectedOption.value : null,
    });
  };

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              pb={{ base: "14px", md: "0px" }}
              color="#fff"
            >
              เช็คข้อมูลฝาก
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <QueryClientProvider client={queryClient}>
              <SearchTableRemote
                query={QueryKeys.DEPOSIT_TRANSACTION_REPORT}
                columnsData={depositTransactionReportColumnsData(
                  handleModalOpen,
                )}
                fetchData={fetchData}
                search={search}
                setSearch={setSearch}
                showExport={true}
                downloadableData={downloadableData}
                filename={pageName}
                showSearchableDropdown={true}
                onChange={handleOptionChange}
                searchOptions={DEPOSIT_TRANSACTION_SEARCH_OPTIONS}
              />
            </QueryClientProvider>
          </CardBody>
        </Animated>
      </Card>
      <ApproveBlockAutoTransactionModal
        isOpen={modalState.approveBlockAutoTransactionModalOpen}
        onClose={() => handleModalClose("approveBlockAutoTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleSaveTransaction}
      />
      <RejectBlockAutoTransactionModal
        isOpen={modalState.rejectBlockAutoTransactionModalOpen}
        onClose={() => handleModalClose("rejectBlockAutoTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleSaveTransaction}
      />
      <ApproveInvalidTransactionModal
        isOpen={modalState.approveInvalidTransactionModalOpen}
        onClose={() => handleModalClose("approveInvalidTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleInvalidTransaction}
      />
      <RejectInvalidTransactionModal
        isOpen={modalState.rejectInvalidTransactionModalOpen}
        onClose={() => handleModalClose("rejectInvalidTransactionModal")}
        transaction={selectedTransaction}
        handleSubmit={handleInvalidTransaction}
      />
      <QrCodeModal
        isOpen={modalState.qrCodeModalOpen}
        onClose={() => handleModalClose("qrCodeModal")}
        transaction={selectedTransaction}
      />
    </Flex>
  );
};

export default DepositTransactionReport;
