import { useState } from "react";
import { fetchSpinnerHistory } from "services/system-setting/spinnerReportService";
import { mapJsonToTableData } from "utils/CommonUtils";
import { spinnerReportColumnsData } from "variables/columns/website-report/spinnerReportColumnsData";

export const useSpinnerReport = () => {
  const [downloadableData, setDownloadableData] = useState([]);
  const fetchData = async (pageIndex, pageSize, search) => {
    const response = await fetchSpinnerHistory(pageIndex, pageSize, search);

    const formattedRecords = mapJsonToTableData(
      response.results,
      spinnerReportColumnsData,
    );
    setDownloadableData(formattedRecords);

    return response;
  };

  return {
    fetchData,
    downloadableData,
  };
};
