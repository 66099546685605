import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchMemberLevel,
  updateMemberLevel,
} from "services/member-management/memberLevelService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useMemberLevel = () => {
  const queryClient = useQueryClient();
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const [memberLevelData, setMemberLevelData] = useState([]);

  const { isLoading } = useQuery(QueryKeys.MEMBER_LEVEL, fetchMemberLevel, {
    onSuccess: (response) => {
      setMemberLevelData(response.data.data);
    },
    onError: (error) => handleError(error, ERROR_MESSAGE),
  });

  const { mutate: saveMemberLevel, isLoading: isSaving } = useMutation(
    () => updateMemberLevel({ memberLevelList: memberLevelData }),
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(QueryKeys.MEMBER_LEVEL);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const handleValueChange = (id, field, value) => {
    setMemberLevelData((prevData) =>
      prevData.map((memberLevel) =>
        memberLevel.id === id
          ? { ...memberLevel, [field]: parseFloat(value) }
          : memberLevel,
      ),
    );
  };

  return {
    memberLevelData,
    isLoading,
    isSaving,
    saveMemberLevel,
    handleValueChange,
  };
};
