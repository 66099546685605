import { useQuery } from "react-query";
import { fetchAffiliateReport } from "services/member-report/affiliateReportService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useAffiliateReport = (search) => {
  const handleError = useErrorHandler();
  return useQuery(
    [QueryKeys.AFFILIATE_REPORT, search.listDateFrom, search.listDateTo],
    async () => {
      const response = await fetchAffiliateReport(search);
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );
};
