import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { banks } from "variables/bank";

export const MemberBankCard = ({ member }) => {
  return (
    <Box
      h="285px"
      bg="#1D2229"
      borderRadius="16px"
      p={6}
      border="1px solid #373F48"
    >
      <Flex align="flex-start" pb="18px">
        <Image
          src={`${banks[member?.bankName]?.logo}`}
          p={2}
          backgroundColor={banks[member?.bankName]?.color}
          boxSize="50px"
          borderRadius="50%"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
        />
        <Stack spacing={1} align="flex-start" ml={6}>
          <Text fontWeight="normal" fontSize="14px">
            ธนาคาร
          </Text>
          <Text fontSize="18px" color="#fff" fontWeight="bold">
            {banks[member?.bankName]?.name_th}
          </Text>
        </Stack>
      </Flex>

      <Flex
        direction="column"
        align="flex-start"
        borderBottom="1px solid #373F48"
        pb="18px"
      >
        <Text fontSize="14px" color="gray.400">
          เลขที่บัญชีธนาคาร
        </Text>
        <Text fontSize="29px" fontWeight="bold" color="green.500">
          {member?.bankAccount}
        </Text>
      </Flex>

      <Stack spacing={1} align="flex-start" mt="16px">
        <Text fontSize="14px" color="#8C9592">
          ชื่อบัญชี
        </Text>
        <Text fontSize="18px" color="#fff" fontWeight="bold">
          {member?.name}
        </Text>
      </Stack>
    </Box>
  );
};
