import { useQuery } from "react-query";
import {
  fetchMarketingTopTransaction,
  fetchTopTransaction,
} from "services/dashboard/dashboardService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE, MARKETING_ROLE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useTopDeposit = (startDate, endDate, role, mid = null) => {
  const handleError = useErrorHandler();

  const fetchTopDeposit = async () => {
    const req = {
      listDateFrom: startDate,
      listDateTo: endDate,
      orderBy: "deposit",
      mid: mid,
    };

    const response =
      role !== MARKETING_ROLE
        ? await fetchTopTransaction(req)
        : await fetchMarketingTopTransaction(req);

    return response.data.data;
  };

  const { data: topDeposit, isLoading: isTopDepositLoading } = useQuery(
    QueryKeys.TOP_TRANSACTION.DEPOSIT(startDate, endDate),
    fetchTopDeposit,
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  return {
    topDeposit,
    isTopDepositLoading,
  };
};
