import { useQuery } from "react-query";
import {
  fetchMarketingLink,
  fetchMarketingStaff,
} from "services/system-setting/staffSettingService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE, PREFIX } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useMarketingSetting = () => {
  const prefix = localStorage.getItem(PREFIX);
  const handleError = useErrorHandler();

  const { data: marketingStaffData, isLoading: isMarketingStaffDataLoading } =
    useQuery(
      [QueryKeys.MARKETING_SETTING],
      async () => {
        const response = await fetchMarketingStaff(prefix);
        return response.data.data;
      },
      {
        onError: (error) => handleError(error, ERROR_MESSAGE),
      },
    );

  const { data: marketingLinkData, isLoading: isMarketingLinkDataLoading } =
    useQuery(
      [QueryKeys.MARKETING_LINK],
      async () => {
        const response = await fetchMarketingLink();
        return response.data.data[1].value;
      },
      {
        onError: (error) => handleError(error, ERROR_MESSAGE),
      },
    );

  return {
    marketingStaffData,
    isMarketingStaffDataLoading,
    marketingLinkData,
    isMarketingLinkDataLoading,
  };
};
