import { useQuery } from "react-query";
import { fetchStaffCreditHistoryReport } from "services/website-report/staffCreditHistoryReportService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useStaffCreditHistory = (search) => {
  const handleError = useErrorHandler();

  return useQuery(
    [
      QueryKeys.STAFF_CREDIT_HISTORY,
      search.startDate,
      search.endDate,
      search.type,
    ],
    async () => {
      const response = await fetchStaffCreditHistoryReport(search);
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );
};
