import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import PageHeader from "components/Layout/PageHeader";
import { Loader } from "components/Loader/Loader";
import SimpleTable from "components/Tables/SimpleTable";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchRole } from "services/system-setting/roleSettingService";
import { roleSettingColumnsData } from "variables/columns/system-setting/roleSettingColumnsData";
import { ERROR_MESSAGE } from "variables/system";

const RoleSetting = ({ pageName }) => {
  const [roleData, setRoleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = useErrorHandler();
  const history = useHistory();

  const columns = useMemo(() => roleSettingColumnsData(history), [history]);

  const fetchRoleData = () => {
    setIsLoading(true);
    fetchRole()
      .then((response) => {
        setRoleData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchRoleData();
  }, []);

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card>
        <CardHeader>
          <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
            ตำแหน่งทั้งหมด
          </Text>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
          <CardBody>
            {isLoading ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                height="400px"
                w="100%"
              >
                <Loader />
              </Flex>
            ) : (
              <SimpleTable
                tableData={roleData}
                columnsData={columns}
                showAction={true}
                actionButtonText={"เพิ่มตำแหน่ง"}
                actionButtonIcon={null}
                navigationUrl={"/admin/system-setting/role/add"}
              />
            )}
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default RoleSetting;
