import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import { AddMemberIcon } from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useCredit } from "hooks/business/member-management/useCredit";
import { useMember } from "hooks/business/member-management/useMember";
import { useMemberReport } from "hooks/business/member-report/useMemberReport";
import { useSearch } from "hooks/common/useSearch";
import React, { useState } from "react";
import { QueryClientProvider, useQueryClient } from "react-query";
import { membersColumnsData } from "variables/columns/member-management/membersColumnsData";
import { QueryKeys } from "variables/queryKeys";
import { APP_PROFILE } from "variables/system";
import { MembersSearchTypes } from "variables/types";

import AddCreditModal from "./components/AddCreditModal";
import AddCreditSlipModal from "./components/AddCreditSlipModal";
import ManageMemberModal from "./components/ManageMemberModal";
import RemoveCreditModal from "./components/RemoveCreditModal";
import ResetPasswordModal from "./components/ResetPasswordModal";

const Members = ({ pageName }) => {
  const queryClient = useQueryClient();

  const [modalState, setModalState] = useState({
    manageMemberModalOpen: false,
    addCreditModalOpen: false,
    addCreditSlipModalOpen: false,
    removeCreditModalOpen: false,
    resetPasswordModalOpen: false,
  });

  const [selectedMember, setSelectedMember] = useState(null);

  const role = JSON.parse(localStorage.getItem(APP_PROFILE)).role;

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
    null,
    null,
    false,
  );

  const { handleSaveMember } = useMember();

  const { fetchData, downloadableData } = useMemberReport(
    role,
    MembersSearchTypes.ALL,
    membersColumnsData,
  );

  const { handleCredit } = useCredit();

  const handleModalOpen = (modal, user = null) => {
    setSelectedMember(user);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedMember(null);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              color="#fff"
              pb={{ base: "14px", md: "0px" }}
            >
              จัดการสมาชิกทั้งหมด
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <QueryClientProvider client={queryClient}>
              <SearchTableRemote
                query={QueryKeys.MEMBERS}
                columnsData={membersColumnsData(handleModalOpen, queryClient)}
                fetchData={fetchData}
                search={search}
                setSearch={setSearch}
                showExport={true}
                showAction={true}
                handleModalOpen={handleModalOpen}
                modalName={"manageMemberModal"}
                actionButtonIcon={AddMemberIcon}
                actionButtonText={"เพิ่มสมาชิก"}
                downloadableData={downloadableData}
                filename={pageName}
              />
            </QueryClientProvider>
          </CardBody>
        </Animated>
      </Card>
      <ManageMemberModal
        isOpen={modalState.manageMemberModalOpen}
        onClose={() => handleModalClose("manageMemberModal")}
        member={selectedMember}
        handleSubmit={handleSaveMember}
      />
      <AddCreditModal
        isOpen={modalState.addCreditModalOpen}
        onClose={() => handleModalClose("addCreditModal")}
        member={selectedMember}
        handleSubmit={handleCredit}
      />
      <AddCreditSlipModal
        isOpen={modalState.addCreditSlipModalOpen}
        onClose={() => handleModalClose("addCreditSlipModal")}
        member={selectedMember}
        handleSubmit={handleCredit}
      />
      <RemoveCreditModal
        isOpen={modalState.removeCreditModalOpen}
        onClose={() => handleModalClose("removeCreditModal")}
        member={selectedMember}
        handleSubmit={handleCredit}
      />
      <ResetPasswordModal
        isOpen={modalState.resetPasswordModalOpen}
        onClose={() => handleModalClose("resetPasswordModal")}
        member={selectedMember}
      />
    </Flex>
  );
};

export default Members;
