import { Flex, Stack, Text } from "@chakra-ui/react";
import "assets/css/styles.css";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SimpleTable from "components/Tables/SimpleTable";
import { useProfitAndLossReport } from "hooks/business/website-report/useProfitAndLossReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { profitLossColumnsData } from "variables/columns/website-report/profitLossColumnsData";
import { APP_PROFILE } from "variables/system";

const ProfitLossReport = ({ pageName }) => {
  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const dateSearchFormat = "yyyy-MM-DD HH:mm:ss";
  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const { search, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );
  const {
    data: profitLossData,
    isLoading,
    downloadableData,
  } = useProfitAndLossReport(role, search);

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Stack direction="column" w="100%">
        <Card pb="38px">
          <CardHeader>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
            >
              <Text
                color="white"
                fontSize="18px"
                fontWeight="bold"
                pb={{ base: "14px", md: "0px" }}
              >
                สรุป กำไร-ขาดทุน
              </Text>
              <CustomRangePicker
                selectedDates={selectedDates}
                onDateChange={handleDateChange}
              />
            </Flex>
          </CardHeader>
          <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
            <CardBody>
              <SimpleTable
                tableData={profitLossData}
                columnsData={profitLossColumnsData}
                showExport={true}
                downloadableData={downloadableData}
                filename={pageName}
                isLoading={isLoading}
              />
            </CardBody>
          </Animated>
        </Card>
      </Stack>
    </Flex>
  );
};

export default ProfitLossReport;
