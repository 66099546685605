import { DEVICE_OPTIONS } from "../variables/options";

export const numberToStringFormat = (num) => {
  const checknum = Number(num);
  if (!isNaN(checknum)) {
    return checknum.toLocaleString("en-US", { style: "decimal" });
  } else {
    return "";
  }
};

export const numberToStringFormat2Digit = (num) => {
  const checknum = Number(num);
  if (!isNaN(checknum)) {
    let digit2 = Number(checknum.toFixed(2));
    return digit2;
  } else {
    return "";
  }
};

export const validateField = (value) => {
  let error;
  if (!value) {
    error = "This field is required";
  }
  return error;
};

export const validateObject = (value, obj) => {
  let error;
  if (!obj && !value) {
    error = "This field is required";
  }
  return error;
};

export const getDeviceText = (value) => {
  const device = DEVICE_OPTIONS.find((device) => device.val === value);
  return device ? device.text : value;
};

export const getMemberLevelName = (memberLevelData, level) => {
  const memberLevel = memberLevelData?.find(
    (member) => parseInt(member.seqLevel) + 1 === level,
  );
  return memberLevel ? memberLevel.levelName : "-";
};

export const getMemberLevelColorScheme = (value) => {
  switch (value) {
    case "4":
      return "#005EE2";
    case "3":
      return "#9C6A1F";
    case "2":
      return "#3F4A56";
    case "1":
      return "#1D2229";
    case "0":
      return "#1D2229";
    default:
      return "#1D2229";
  }
};

export const mapJsonToTableData = (jsonData, columnsData) => {
  return jsonData.map((item) => {
    const mappedRow = {};

    columnsData.forEach((column) => {
      const accessor = column.accessor;
      const header = column.Header;

      if (accessor && header) {
        mappedRow[header] = item[accessor] !== undefined ? item[accessor] : "-";
      }
    });

    return mappedRow;
  });
};

export const getProviderNiceName = (name) => {
  switch (name) {
    case "pg":
      return "PGSOFT";
    case "dg":
      return "Dream Gaming";
    case "pp":
      return "Pragmatic Play";
    case "sa":
      return "SA Gaming";
    case "sexy":
      return "Sexy Gaming";
    case "hab":
      return "Habanero";
    case "joker":
      return "Joker";
    case "ep":
      return "EvoPlay";
    case "fc":
      return "Fachai";
    case "mg":
      return "Micro Gaming";
    case "bpg":
      return "Blueprint Gaming";
    case "rlx":
      return "Relax Gaming";
    case "bng":
      return "Booongo";
    case "kgl":
      return "Kalamba Games";
    case "bg":
      return "BG";
    case "ygg":
      return "Yggdrasil";
    case "waz":
      return "Wazdan";
    case "aws":
      return "AE Gaming Slot";
    case "ag":
      return "Asia Gaming";
    case "ng":
      return "Naga Games";
    case "netent":
      return "NetEnt";
    case "ttg":
      return "TTG";
    case "swg":
      return "SkyWind Group";
    case "bs":
      return "Betsoft";
    case "bfs":
      return "BF Sports";
    case "we":
      return "WE Entertainment";
    case "wm":
      return "WM";
    case "eg":
      return "Evolution Gaming";
    case "km":
      return "KINGMAKER";
    case "funky":
      return "Funky Games";
    case "ps":
      return "PlayStar";
    case "1x2":
      return "1X2 Gaming";
    case "qs":
      return "Quickspin";
    case "ga":
      return "Game Art";
    case "sp":
      return "SimplePlay";
    case "ds":
      return "Dragoon Soft";
    case "nlc":
      return "Nolimit City";
    case "red":
      return "Red Tiger";
    case "ids":
      return "Iron Dog";
    case "tk":
      return "Thunderkick";
    case "elk":
      return "ELK Studios";
    case "prs":
      return "Print Studios";
    case "hak":
      return "Hacksaw Gaming";
    case "png":
      return "Play n Go";
    case "max":
      return "Maverick";
    case "pug":
      return "Push Gaming";
    case "nge":
      return "NetGames Entertainment";
    case "fng":
      return "Fantasma Gaming";
    default:
      return name;
  }
};

export const getCommissionTypeNiceName = (type) => {
  switch (type) {
    case "EARN_POINT":
      return "รับพ๊อยต์";
    case "COMM_CREDIT":
      return "ค่าคอมมิชชั่น";
    case "TRANS_CREDIT":
      return "แลกพ๊อยต์";
  }
};

export const getJackpotBackground = (type) => {
  switch (type) {
    case "Grand":
      return "#F7AB1E";
    case "Major":
      return "#2E4DB2";
    case "Minor":
      return "#4F4A43";
    case "Mini":
      return "#373F48";
  }
};

export const getStaffCreditStatusBackground = (status) => {
  switch (status) {
    case "NO_SLIP":
      return "#F7AB1E";
    case "CUT_CREDIT":
      return "#BB344C";
    case "ADD_CREDIT":
      return "#31AA93";
  }
};

export const getStaffCreditStatusNiceName = (status) => {
  switch (status) {
    case "NO_SLIP":
      return "สลิปไม่แสดง";
    case "CUT_CREDIT":
      return "ลบเครดิต";
    case "ADD_CREDIT":
      return "เพิ่มเครดิต";
  }
};

export const getTransactionStatusBackground = (status) => {
  switch (status) {
    case "SUCCESS":
      return "#31AA93";
    case "PENDING":
      return "#FFD82B";
    case "ERROR":
      return "#BB344C";
    case "BLOCK_AUTO":
      return "#B86410";
    case "NOT_SURE":
      return "#3E4246";
    case "REJECT":
      return "#5C2429";
    case "REJECT_N_REFUND":
      return "#521017";
  }
};
