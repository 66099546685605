import { Flex, Image, Text } from "@chakra-ui/react";
import avatar8 from "assets/img/avatars/avatar8.png";
import { formatDateWithoutMeridiem } from "utils/DateformatUtils";

import { banks } from "../../bank";

export const dashboardLastDeposit = [
  {
    Header: "ยูสเซอร์เนม",
    accessor: "username",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "ธนาคาร",
    accessor: "bankCode",
    Cell: ({ cell, row }) => (
      <Flex align="center" justify="center" w="100%">
        <Image
          src={`${banks[row.original.bankCode]?.logo}`}
          alt={cell.value}
          backgroundColor={banks[row.original.bankCode]?.color}
          boxSize="30px"
          borderRadius="50%"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          padding="5px"
        />
      </Flex>
    ),
  },
  {
    Header: "ยอด",
    accessor: "amount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => {
      return (
        <Text fontWeight="bold" color="#fff">
          {value}
        </Text>
      );
    },
  },
  {
    Header: "โบนัส",
    accessor: "bonus",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => {
      return (
        <Text fontWeight="bold" color="#fff">
          {value}
        </Text>
      );
    },
  },
  {
    Header: "หมายเหตุ",
    accessor: "reason",
    disableSortBy: true,
  },
  {
    Header: "เวลา",
    accessor: "createdDate",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ value }) => formatDateWithoutMeridiem(value),
  },
];

export const dashboardLastWithdraw = [
  {
    Header: "ยูสเซอร์เนม",
    accessor: "username",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "ธนาคาร",
    accessor: "bankCode",
    Cell: ({ cell, row }) => (
      <Flex align="center" justify="center" w="100%">
        {row.original.bankCode ? (
          <Image
            src={`${banks[row.original.bankCode]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankCode]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            padding="5px"
          />
        ) : (
          <Image
            src={avatar8}
            alt={cell.value}
            backgroundColor={banks[row.original.bankCode]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          />
        )}
      </Flex>
    ),
  },
  {
    Header: "ยอด",
    accessor: "amount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => {
      return (
        <Text fontWeight="bold" color="#fff">
          {value}
        </Text>
      );
    },
  },
  {
    Header: "หมายเหตุ",
    accessor: "reason",
    disableSortBy: true,
  },
  {
    Header: "เวลา",
    accessor: "createdDate",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ value }) => formatDateWithoutMeridiem(value),
  },
];
