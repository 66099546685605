import { Text } from "@chakra-ui/react";
import { formatDateWithoutMeridiem } from "utils/DateformatUtils";

export const memberRecentDepositTransactionColumnsData = [
  {
    Header: "โบนัส",
    accessor: "bonus",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: "โปร",
    accessor: "promotionName",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "เครดิตก่อนเติม",
    accessor: "beforeAmount",
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: "เติม",
    accessor: "addCredit",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: "เครดิตหลังเติม",
    accessor: "afterAmount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: "เวลา",
    accessor: "createdDate",
    Cell: ({ value }) => formatDateWithoutMeridiem(value),
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "หมายเหตุ",
    accessor: "reason",
    textAlign: "left",
    justifyContent: "flex-start",
  },
];
