import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { validateField } from "utils/CommonUtils";
import { MistakeCreditTypes } from "variables/types";

const RemoveCreditModal = ({ isOpen, onClose, member, handleSubmit }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              ตัดเครติด
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={0}>
          <Formik
            initialValues={{
              type: MistakeCreditTypes.CUT_CREDIT,
              username: member?.username,
              credit: "",
              reason: "",
              turnOver: 0,
              bonus: "0",
              typeGame: "ALL",
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Field name="credit" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.credit && form.touched.credit}
                      isRequired
                    >
                      <FormLabel
                        fontSize="16px"
                        color="gray.500"
                        mb="5px"
                        pt={4}
                      >
                        จำนวนเงิน
                      </FormLabel>
                      <NumberInput
                        defaultValue={0}
                        precision={2}
                        step={0.2}
                        value={props.values.credit}
                        onChange={(valueString) =>
                          props.setFieldValue("credit", valueString)
                        }
                      >
                        <NumberInputField
                          ref={initialRef}
                          {...field}
                          fontSize="16px"
                        />
                      </NumberInput>
                    </FormControl>
                  )}
                </Field>
                <Field name="reason" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={form.errors.reason && form.touched.reason}
                      isRequired
                    >
                      <FormLabel>หมายเหตุ</FormLabel>
                      <Input
                        {...field}
                        placeholder="หมายเหตุ"
                        fontSize="16px"
                      />
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mb={6}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                    fontWeight="normal"
                    fontSize="14px"
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RemoveCreditModal;
