import {
  Flex,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import AnimatedNumber from "components/AnimatedNumber/AnimatedNumber";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import IconBox from "components/Icons/IconBox";
import React from "react";

const StaffCreditReportCard = ({ icon, title, number, isInteger }) => {
  const cleanNumber = parseFloat(number?.replace(/,/g, ""));

  return (
    !isNaN(cleanNumber) && (
      <Card border="1px solid #373F48" bgColor="gray.900">
        <CardBody h="100%">
          <Flex direction="column" justify="space-between" w="100%" h="100%">
            <Flex justify="space-between" align="center" w="100%">
              <StatGroup w="100%">
                <Stat w="100%">
                  <Flex justify="space-between" align="center" w="100%">
                    <Stack align="flex-start">
                      <StatLabel>{title}</StatLabel>
                      <StatNumber>
                        <AnimatedNumber
                          n={cleanNumber}
                          dp={isInteger ? 0 : 2}
                          mode={"Deposit"}
                        />
                      </StatNumber>
                    </Stack>
                    <IconBox
                      w={{ base: "30px", md: "50px" }}
                      h={{ base: "30px", md: "50px" }}
                      backgroundColor="rgba(55, 63, 72, 1)"
                      ml={4} // Optional: add spacing between the Stack and IconBox
                    >
                      {icon}
                    </IconBox>
                  </Flex>
                </Stat>
              </StatGroup>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    )
  );
};

export default StaffCreditReportCard;
