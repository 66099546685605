export const switchStyles = {
  components: {
    Switch: {
      // Base styles
      baseStyle: {
        track: {
          bg: "#373F48",
          _checked: {
            bg: "#09483D",
          },
        },
        thumb: {
          bg: "#1D2229",
          _checked: {
            bg: "#31AA93",
          },
        },
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
};
