import { Badge, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { DeleteIcon, EditIcon, TagIcon } from "components/Icons/Icons";

export const memberTagColumnsData = (handleModalOpen) => [
  {
    Header: "ชื่อแท็ค",
    accessor: "name",
    textAlign: "left",
    justifyContent: "flex-start",
    disableSortBy: true,
    Cell: ({ cell, row }) => (
      <Flex align="center">
        <Icon as={TagIcon} color={row.original.color} mr={2} />
        <Text color={row.original.color} fontWeight="bold">
          {cell.value}
        </Text>
      </Flex>
    ),
  },
  {
    Header: "รายระเอียด",
    accessor: "description",
    disableSortBy: true,
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "สีแท็ค",
    accessor: "color",
    Cell: ({ cell }) => (
      <Flex align="center" justify="center">
        <Badge
          backgroundColor={cell.value}
          w="22px"
          h="22px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={"#000000"}
        />
      </Flex>
    ),
    disableSortBy: true,
  },
  {
    Header: "เครื่องมือ",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ row }) => (
      <>
        <IconButton
          variant="solid"
          bg={"green.500"}
          color={"#fff"}
          aria-label="edit"
          size="sm"
          borderRadius="28px"
          w="40px"
          h="30px"
          icon={<EditIcon h="14px" w="14px" color="#fff" />}
          onClick={() => handleModalOpen("manageMemberTagModal", row.original)}
          mr="6px"
        />
        <IconButton
          variant="solid"
          bg={"red.500"}
          color={"#fff"}
          aria-label="delete"
          size="sm"
          borderRadius="28px"
          w="40px"
          h="30px"
          icon={<DeleteIcon />}
          onClick={() => handleModalOpen("deleteMemberTagModal", row.original)}
        >
          แก้ไข
        </IconButton>
      </>
    ),
    disableSortBy: true,
  },
];
