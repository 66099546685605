import { Text } from "@chakra-ui/react";

export const profitLossColumnsData = [
  {
    Header: "ฝาก",
    accessor: "deposit",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: "โบนัส",
    accessor: "bonus",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
    width: "375px",
  },
  {
    Header: "ฝาก+โบนัส",
    accessor: "depositBonus",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: "ถอน",
    accessor: "withdraw",
    disableSortBy: true,
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: "กำไรขาดทุน",
    accessor: "profitAndLoss",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text
        color={value === 0 ? "#fff" : value < 0 ? "red.500" : "green.500"}
        fontWeight="bold"
      >
        {value}
      </Text>
    ),
    disableSortBy: true,
  },
];
