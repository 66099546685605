const CardHeader = {
  baseStyle: {
    // display: "flex",
    width: "100%",
    py: "12px",
  },
};

export const CardHeaderComponent = {
  components: {
    CardHeader,
  },
};
