import vp from "assets/img/payment-gateway/vizpay.png";
import wp from "assets/img/payment-gateway/wildpay.png";

export const paymentGateway = {
  wildpay: {
    logo: wp,
    nice_name: "Wildpay",
    color: "#fff",
  },
  vizpay: {
    logo: vp,
    nice_name: "Vizpay",
    color: "#fff",
  },
};
