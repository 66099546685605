import { useEffect, useState } from "react";

import allRoutes from "../../routes";
import { APP_ROLES } from "../../variables/system";

const getFilteredRoutes = (roles, routes) => {
  let tmp = [];
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].items && routes[i].items.length > 0) {
      const item = routes[i].items.filter((el) => {
        return roles.some((f) => {
          return f.menuId === el.menuId;
        });
      });
      let tmp2 = { ...routes[i] };
      tmp2.items = item;
      if (item.length > 0) {
        tmp.push(tmp2);
      }
    } else {
      let tmp2 = { ...routes[i] };
      const found = roles.find((element) => element.menuId === tmp2.menuId);
      if (found) {
        tmp.push(tmp2);
      }
    }
  }
  return tmp;
};

const useDashRoutes = () => {
  const [dashRoutes, setDashRoutes] = useState(allRoutes);

  useEffect(() => {
    const roles =
      localStorage.getItem(APP_ROLES) != null
        ? JSON.parse(localStorage.getItem(APP_ROLES))
        : [];
    const filteredRoutes = getFilteredRoutes(roles, allRoutes);
    setDashRoutes(filteredRoutes);
  }, []);

  return dashRoutes;
};

export default useDashRoutes;
