import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ConfirmDeleteIcon } from "components/Icons/Icons";
import React from "react";

const DeleteTrueWalletModal = ({
  isOpen,
  onClose,
  trueWallet,
  handleDelete,
  isDeleting,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">ยืนยันทำรายการ</Box>
        </ModalHeader>
        <ModalCloseButton />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="26px"
          mb="12px"
        >
          <ConfirmDeleteIcon w="60px" h="70px" color="red.500" />
        </Box>
        <ModalBody textAlign="center">
          <Text fontSize="20px" fontWeight="bold" color="#fff" mb={1}>
            แน่ใจว่าต้องการลบรายการนี้?
          </Text>
          <Text fontSize="14px" fontWeight="normal" color="gray.500" mb={1}>
            ลบทรูวอลเล็ต:{" "}
            <Text as="span" fontWeight="bold">
              {trueWallet?.name}
            </Text>
          </Text>
        </ModalBody>
        <ModalFooter mb="12px">
          <Flex w="100%">
            <Button
              flex="1"
              backgroundColor="#373F48"
              color="#8C9592"
              onClick={() => {
                onClose();
              }}
              mr="10px"
              w="100%"
              fontWeight="normal"
              fontSize="14px"
            >
              ยกเลิก
            </Button>
            <Button
              flex="2"
              backgroundColor="red.500"
              color="#fff"
              fontWeight="normal"
              fontSize="14px"
              onClick={() => {
                handleDelete(trueWallet?.id);
                onClose();
              }}
              isLoading={isDeleting}
              w="100%"
            >
              ยืนยัน
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteTrueWalletModal;
