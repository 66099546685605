import { fetchStaffLoginReport } from "services/website-report/staffLoginReportService";

export const useStaffLoginReport = () => {
  const fetchData = async (pageIndex, pageSize, search) => {
    return await fetchStaffLoginReport(pageIndex, pageSize, search);
  };

  return {
    fetchData,
  };
};
