import { callapi } from "../../utils/APIUtils";

export const addBankSetting = (payload) => {
  return callapi("/api/admin/bank", "POST", payload);
};

export const fetchBankSetting = () => {
  return callapi("/api/admin/bank", "GET", {});
};

export const deleteBankSetting = (id) => {
  return callapi(`/api/admin/bank/${id}`, "DELETE", {});
};

export const updateBankSetting = (id, payload) => {
  return callapi(`/api/admin/bank/${id}`, "PUT", payload);
};
