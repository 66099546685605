import { Flex, Grid, Icon, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import {
  StaffAddCreditIcon,
  StaffRemoveCreditIcon,
  StaffSlipNotFoundIcon,
} from "components/Icons/Icons";
import PageHeader from "components/Layout/PageHeader";
import SearchTable from "components/Tables/SearchTable";
import { useStaffCreditHistory } from "hooks/business/website-report/useStaffCreditHistory";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { staffCreditHistoryColumnsData } from "variables/columns/website-report/staffCreditHistoryColumnsData";
import { STAFF_CREDIT_HISTORY_SEARCH_OPTIONS } from "variables/options";

import StaffCreditReportCard from "../staff-credit/components/StaffCreditReportCard";

const StaffCreditReport = ({ pageName }) => {
  const dateSearchFormat = "DD/MM/yyyy HH:mm:ss";

  const dateFieldsNames = {
    from: "startDate",
    to: "endDate",
  };

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
    true,
  );

  const { data: staffCreditData, isLoading } = useStaffCreditHistory(search);

  const summaryData = staffCreditData?.summary || {
    noSlip: 0,
    cutCredit: 0,
    addCredit: 0,
  };
  const listData = staffCreditData?.list || [];

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      type: selectedOption ? selectedOption.value : null,
    });
  };

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Animated animation="fadeInUp" duration="0.5s" delay="0.1s">
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
          gap={{ base: "12px", md: "24px" }}
          pb="12px"
        >
          <StaffCreditReportCard
            title={"เพิ่มเครดิต"}
            icon={
              <Icon
                as={StaffAddCreditIcon}
                w={{ base: "20px", md: "30px" }}
                h={{ base: "20px", md: "30px" }}
                color="#8C9592"
              />
            }
            number={summaryData.addCredit.toString()}
            isInteger={false}
          />
          <StaffCreditReportCard
            title={"สลิปไม่แสดง"}
            number={summaryData.noSlip.toString()}
            icon={
              <Icon
                as={StaffSlipNotFoundIcon}
                w={{ base: "20px", md: "30px" }}
                h={{ base: "20px", md: "30px" }}
                color="#8C9592"
              />
            }
            isInteger={false}
          />
          <StaffCreditReportCard
            title={"ลบเครดิต"}
            number={summaryData.cutCredit.toString()}
            icon={
              <Icon
                as={StaffRemoveCreditIcon}
                w={{ base: "20px", md: "30px" }}
                h={{ base: "20px", md: "30px" }}
                color="#8C9592"
              />
            }
            isInteger={false}
          />
        </Grid>
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Text
              color="white"
              fontSize="18px"
              fontWeight="bold"
              pb={{ base: "14px", md: "0px" }}
            >
              {pageName}
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <SearchTable
              isLoading={isLoading}
              tableData={listData}
              showSearchableDropdown={true}
              searchOptions={STAFF_CREDIT_HISTORY_SEARCH_OPTIONS}
              onChange={handleOptionChange}
              columnsData={staffCreditHistoryColumnsData}
            />
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default StaffCreditReport;
