import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTable from "components/Tables/SearchTable";
import { useAffiliateReport } from "hooks/business/member-report/useAffiliateReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { affiliateReportColumnsData } from "variables/columns/member-report/affiliateReportColumnsData";

const AffiliateReport = ({ pageName }) => {
  const dateFieldsNames = {
    from: "listDateFrom",
    to: "listDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm:ss";

  const { search, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );
  const { data: affiliateReportData, isLoading } = useAffiliateReport(search);

  return (
    <Flex direction="column">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              pb={{ base: "14px", md: "0px" }}
              color="#fff"
            >
              รายงานแนะนำเพื่อน
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <SearchTable
              isLoading={isLoading}
              tableData={affiliateReportData}
              columnsData={affiliateReportColumnsData()}
              hideSearch={false}
            />
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default AffiliateReport;
