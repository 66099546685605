import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useSpinnerReport } from "hooks/business/website-report/useSpinnerReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { spinnerReportColumnsData } from "variables/columns/website-report/spinnerReportColumnsData";
import { QueryKeys } from "variables/queryKeys";

const SpinnerReport = ({ pageName }) => {
  const queryClient = new QueryClient();
  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { fetchData, downloadableData } = useSpinnerReport();
  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Text
              color="white"
              fontSize="18px"
              fontWeight="bold"
              pb={{ base: "14px", md: "0px" }}
            >
              ประวัติการการหมุนวงล้อ
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <QueryClientProvider client={queryClient}>
              <SearchTableRemote
                query={QueryKeys.SPINNER_REPORT}
                columnsData={spinnerReportColumnsData}
                fetchData={fetchData}
                search={search}
                setSearch={setSearch}
                showExport={true}
                downloadableData={downloadableData}
                filename={pageName}
              />
            </QueryClientProvider>
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default SpinnerReport;
