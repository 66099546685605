import { callapi } from "../../utils/APIUtils";

export const addStaff = (payload) => {
  return callapi("/api/admin/register", "POST", payload);
};

export const fetchStaff = (prefix) => {
  return callapi(`/api/admin/user-list/${prefix}`, "GET", {});
};

export const fetchStaffRole = () => {
  return callapi("/api/admin/rolemenu/all", "GET", {});
};

export const fetchMarketingStaff = (prefix) => {
  return callapi(`/api/admin/marketing/user-list/${prefix}`, "GET", {});
};

export const fetchMarketingLink = () => {
  return callapi("/api/admin/url", "GET", {});
};

export const updateStaff = (payload) => {
  return callapi("/api/admin/update-admin", "PUT", payload);
};

export const fetchMarketingInfo = (payload) => {
  return callapi("/api/user/marketingInfo", "POST", payload);
};
