import { Flex, Text } from "@chakra-ui/react";
import Animated from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import PageHeader from "components/Layout/PageHeader";
import SearchTable from "components/Tables/SearchTable";
import { useCommissionReport } from "hooks/business/member-report/useCommissionReport";
import { useSearch } from "hooks/common/useSearch";
import React from "react";
import { commissionReportColumnsData } from "variables/columns/member-report/commissionReportColumnsData";

const CommissionReport = ({ pageName }) => {
  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
    true,
    "",
  );

  const { data: commissionReportData, isLoading } = useCommissionReport(search);

  const searchOptions = [
    {
      label: "ทั้งหมด",
      value: "",
    },
    {
      label: "รับพ๊อยต์",
      value: "EARN_POINT",
    },
    {
      label: "ค่าคอมมิชชั่น",
      value: "COMM_CREDIT",
    },
    {
      label: "แลกพ๊อยต์",
      value: "TRANS_CREDIT",
    },
  ];

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      type: selectedOption ? selectedOption.value : null,
    });
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Animated animation="fadeInUp" duration="0.6s" delay="0.1s">
        <PageHeader pageName={pageName} />
      </Animated>
      <Card pb="38px">
        <CardHeader>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Text
              fontSize="18px"
              fontWeight="bold"
              color="#fff"
              pb={{ base: "14px", md: "0px" }}
            >
              รายงานคอมมิชชั่น
            </Text>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Flex>
        </CardHeader>
        <Animated animation="fadeInUp" duration="0.8s" delay="0.1s">
          <CardBody>
            <SearchTable
              isLoading={isLoading}
              columnsData={commissionReportColumnsData()}
              tableData={commissionReportData}
              showSearchableDropdown={true}
              onChange={handleOptionChange}
              searchOptions={searchOptions}
            />
          </CardBody>
        </Animated>
      </Card>
    </Flex>
  );
};

export default CommissionReport;
