import { useState } from "react";
import { useQuery } from "react-query";
import {
  fetchMarketingProfitLossReport,
  fetchProfitLossReport,
} from "services/website-report/profitLossReportService";
import { mapJsonToTableData } from "utils/CommonUtils";
import { profitLossColumnsData } from "variables/columns/website-report/profitLossColumnsData";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE, MARKETING_ROLE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useProfitAndLossReport = (role, search) => {
  const handleError = useErrorHandler();
  const [downloadableData, setDownloadableData] = useState([]);

  const fetchData = async () => {
    const response =
      role !== MARKETING_ROLE
        ? await fetchProfitLossReport(search)
        : await fetchMarketingProfitLossReport(search);
    return [response.data.data];
  };

  const queryKey = [
    QueryKeys.PROFIT_AND_LOSS_REPORT,
    search.createdDateFrom,
    search.createdDateTo,
  ];

  const { data, isLoading } = useQuery(queryKey, fetchData, {
    onError: (error) => handleError(error, ERROR_MESSAGE),
    onSuccess: (data) => {
      const formattedRecords = mapJsonToTableData(data, profitLossColumnsData);
      setDownloadableData(formattedRecords);
    },
  });

  return {
    data,
    isLoading,
    downloadableData,
  };
};
