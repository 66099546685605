import { callapi } from "../../utils/APIUtils";

export const addTrueWalletSetting = (payload) => {
  return callapi("/api/admin/truewallet", "POST", payload);
};

export const fetchTrueWalletSetting = () => {
  return callapi("/api/admin/truewallet", "GET", {});
};

export const updateTrueWalletSetting = (id, payload) => {
  return callapi(`/api/admin/truewallet/${id}`, "PUT", payload);
};

export const deleteTrueWalletSetting = (id) => {
  return callapi(`/api/admin/truewallet/${id}`, "DELETE", {});
};
