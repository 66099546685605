import { Badge, Flex, IconButton, Image, Text } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/Icons";
import { getTransactionStatusBackground } from "utils/CommonUtils";
import { formatDateWithoutMeridiem } from "utils/DateformatUtils";
import ActionButtons from "views/transaction-report/withdraw/components/ActionButtons";

import { banks } from "../../bank";
import { getWithdrawSName } from "../../system";

const StatusBadge = ({ status }) => {
  return (
    <Flex align="center">
      <Badge
        bg={getTransactionStatusBackground(status)}
        px={3}
        h="28px"
        speed="0.8s"
        borderRadius="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {
          <Text
            color={status === "PENDING" ? "#373F48" : "#fff"}
            fontWeight="normal"
          >
            {getWithdrawSName(status)}
          </Text>
        }
      </Badge>
    </Flex>
  );
};

export const withdrawTransactionColumnsData = (handleModalOpen) => {
  return [
    {
      Header: "สถานะ",
      accessor: "status",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => <StatusBadge status={cell.value} />,
    },
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "ชื่อ-สกุล",
      accessor: "fullName",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "ยอดเงินถอน",
      accessor: "amount",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "เลขที่บัญชี",
      accessor: "accountNumber",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "เครดิตก่อนถอน",
      accessor: "beforeAmount",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "เครดิตหลังถอน",
      accessor: "afterAmount",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "ธนาคารที่ใช้ถอน",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          <Image
            src={`${banks[row.original.bankCode]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankCode]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            padding="5px"
          />
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "เครื่องมือ",
      minWidth: "295px",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => (
        <ActionButtons row={row} handleModalOpen={handleModalOpen} />
      ),
      disableSortBy: true,
    },
    {
      Header: "เงินในบัญชี",
      accessor: "remainBalance",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "เงินในบัญชีก่อนถอน",
      accessor: "beforeTransfer",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "เงินในบัญชีหลังถอน",
      accessor: "afterTransfer",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "ผู้ทำรายการ",
      accessor: "createdBy",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "แก้ไขโดย",
      accessor: "updatedBy",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "วันที่ทำรายการ",
      accessor: "createdDate",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => formatDateWithoutMeridiem(value),
    },
    {
      Header: "วันที่อัพเดต",
      accessor: "updatedDate",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => formatDateWithoutMeridiem(value),
    },
    {
      Header: "แก้ไข",
      Cell: ({ row }) => (
        <IconButton
          variant="solid"
          aria-label="edit"
          size="sm"
          borderRadius="28px"
          w="40px"
          h="30px"
          bg="green.500"
          icon={<EditIcon h="18px" w="18px" color="#fff" />}
          onClick={() =>
            handleModalOpen(
              "updateWithdrawTransactionStatusModal",
              row.original,
            )
          }
        >
          แก้ไข
        </IconButton>
      ),
      disableSortBy: true,
    },
  ];
};
