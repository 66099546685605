import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  fetchWebSetting,
  updateWebSetting,
} from "services/website-setting/webSettingService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useWebSetting = () => {
  const [webSettingData, setWebSettingData] = useState(null);
  const [webSiteUrl, setWebsiteUrl] = useState("");
  const [iframeKey, setIframeKey] = useState(0);
  const [view, setView] = useState("desktop");

  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const { isLoading } = useQuery(QueryKeys.WEB_SETTING, fetchWebSetting, {
    onSuccess: (response) => {
      const data = response.data.data;
      setWebSettingData(data);
      setWebsiteUrl(data?.website);
    },
    onError: (error) => {
      handleError(error, ERROR_MESSAGE);
    },
  });

  const mutation = useMutation(updateWebSetting, {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      setIframeKey((prevKey) => prevKey + 1);
    },
    onError: (error) => {
      handleError(error, ERROR_MESSAGE);
    },
  });

  const handleSubmit = (values) => {
    const req = { ...webSettingData, ...values };
    mutation.mutate(req);
  };

  return {
    webSettingData,
    webSiteUrl,
    iframeKey,
    view,
    setView,
    isLoading,
    handleSubmit,
    isUpdating: mutation.isLoading,
  };
};
