export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const PREFIX = "PREFIX";
export const APP_TOKEN = "APP_TOKEN";
export const APP_PROFILE = "APP_PROFILE";
export const APP_ROLES = "APP_ROLES";

export const USER_SEARCH = "USER_SEARCH";

export const ACCESS_TOKEN = "Bearer ";

export const AUTH_URL = "/api/admin/auth";
export const URL_UPLOAD = "/api/file/uploadFile";
export const URL_DOWNLOAD = "/api/file/downloadFile/";
export const URL_WEBSOCKET = "/noti-websocket";

export const FILTER_DELAY = 1500;
// Role
export const EXCLUSIVE_ROLE = "XSUPERADMIN";
export const SUPER_ROLE = "SUPERADMIN";
export const ADMIN_ROLE = "ADMIN";
export const STAFF_ROLE = "STAFF";
export const MARKETING_ROLE = "MARKETING";

export const ROLE_LIST = [SUPER_ROLE, ADMIN_ROLE, STAFF_ROLE];

// Action
export const VIEW = "VIEW";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

// Message
export const SUCCESS_MESSAGE = "ทำรายการสำเร็จ";
export const ERROR_MESSAGE = "ระบบไม่สามารถดำเนินการได้";

export const getWithdrawSName = (status) => {
  switch (status) {
    case "SUCCESS":
      return "success";
    case "PENDING":
      return "inprogress";
    case "ERROR":
      return "error";
    case "REJECT":
      return "reject";
    case "REJECT_N_REFUND":
      return "rejectAndRefund";
    case "BLOCK_AUTO":
      return "blockAuto";
    default:
      return "";
  }
};

export const getDepositStatus = (status) => {
  switch (status) {
    case "SUCCESS":
      return "success";
    case "PENDING":
      return "inprogress";
    case "ERROR":
      return "error";
    case "REJECT":
      return "reject";
    case "REJECT_N_REFUND":
      return "rejectAndRefund";
    case "BLOCK_AUTO":
      return "blockAuto";
    case "NOT_SURE":
      return "notSure";
    default:
      return "";
  }
};

export const BANK_MAP = {
  "004": "KBANK",
  "014": "SCB",
  "002": "BBL",
  "025": "BAY",
  "006": "KTB",
  "011": "TTB",
  "030": "GSB",
  "034": "BAAC",
  "070": "CIMB",
  "024": "UOBT",
  "069": "KKP",
  "067": "TISCO",
  "017": "CITI",
  "033": "GHB",
  "066": "IBANK",

  1001: "BCEL",
  1002: "LDB",
  1003: "STB",
  1004: "BFL",
  1005: "LVB",
  1006: "JDB",
  1007: "KBANK",
  1008: "LDB2",
  1009: "MJBL",
  1010: "APB",
  1011: "PSVB",
  1012: "BIC",
};
