import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import { useSuccessHandler } from "hooks/common/useSuccessHandler";
import React from "react";
import { createWithdrawCredit } from "services/member-management/memberProfileService";
import { validateField } from "utils/CommonUtils";
import { ERROR_MESSAGE } from "variables/system";

const WithdrawCreditModal = ({ isOpen, onClose, username, onSuccess }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  // TODO: check if 2 dp allow from api
  const handleWithdrawCredit = async (values, actions) => {
    createWithdrawCredit(values)
      .then((response) => {
        if (response.status === 200) {
          handleSuccess(response.data.message);
          onSuccess();
        }
        onClose();
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text color="#fff">ถอนเงิน</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              username: username,
              credit: 0,
            }}
            onSubmit={(values, actions) => {
              handleWithdrawCredit(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Field name="credit" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.credit && form.touched.credit}
                      isRequired
                    >
                      <FormLabel fontSize="xs" fontWeight="bold">
                        จำนวนเงิน
                      </FormLabel>
                      <NumberInput
                        defaultValue={0}
                        // step={0.2}
                        value={props.values.credit}
                        onChange={(valueString) =>
                          props.setFieldValue("credit", valueString)
                        }
                      >
                        <NumberInputField
                          ref={initialRef}
                          {...field}
                          fontSize="sm"
                        />
                      </NumberInput>
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color="#000000"
                    w="100%"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                    type="submit"
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WithdrawCreditModal;
