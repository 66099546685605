import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { Field, Form, Formik } from "formik";
import React from "react";
import { validateField } from "utils/CommonUtils";
import { MistakeCreditTypes } from "variables/types";

const AddCreditSlipModal = ({ isOpen, onClose, member, handleSubmit }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              เพิ่่มรายการฝาก
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={0}>
          <Text
            fontSize="16px"
            color="green.500"
            fontWeight="bold"
            pt={4}
            pb={4}
          >
            เพิ่มรายการฝาก (สลิปไม่แสดง)
          </Text>
          <Formik
            initialValues={{
              type: MistakeCreditTypes.NO_SLIP,
              username: member?.username,
              credit: "",
              reason: "",
              turnover: 0,
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Field name="credit" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.credit && form.touched.credit}
                      isRequired
                      mb={4}
                    >
                      <FormLabel>จำนวนเงิน</FormLabel>
                      <NumberInput
                        defaultValue={0}
                        precision={2}
                        step={0.2}
                        value={props.values.credit}
                        onChange={(valueString) =>
                          props.setFieldValue("credit", valueString)
                        }
                      >
                        <NumberInputField
                          ref={initialRef}
                          {...field}
                          fontSize="16px"
                        />
                      </NumberInput>
                    </FormControl>
                  )}
                </Field>
                <ImageUploader
                  initialImage={""}
                  onUpload={(uri) => props.setFieldValue("image", uri)}
                />
                <Field name="reason" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={form.errors.reason && form.touched.reason}
                      isRequired
                    >
                      <FormLabel>หมายเหตุ</FormLabel>
                      <Input
                        {...field}
                        placeholder="หมายเหตุ"
                        fontSize="16px"
                      />
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mb={6}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    fontWeight="normal"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                    type="submit"
                    fontSize="14px"
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddCreditSlipModal;
