import {
  fetchMarketingMemberReceivePromotion,
  fetchMemberReceivePromotion,
} from "services/member-report/memberRecievePromotionReportService";
import { MARKETING_ROLE } from "variables/system";

export const useMemberReceivePromotion = (role) => {
  const fetchData = async (pageIndex, pageSize, search) => {
    const response =
      role !== MARKETING_ROLE
        ? await fetchMemberReceivePromotion(pageIndex, pageSize, search)
        : await fetchMarketingMemberReceivePromotion(
            pageIndex,
            pageSize,
            search,
          );
    return response;
  };

  return {
    fetchData,
  };
};
