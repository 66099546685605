export const DEVICE_OPTIONS = [
  { val: "1", text: "Mobile" },
  { val: "2", text: "Desktop" },
];

export const BONUS_OPTIONS = [
  { typeCode: "FIX", typeName: "ค่าคงที่" },
  { typeCode: "PERCENT", typeName: "%" },
];

export const AFFILIATE_TYPE_OPTIONS = [
  { typeCode: "DEPOSIT", typeName: "จากยอดฝาก" },
  { typeCode: "WINLOSE", typeName: "จากยอด แพ้/ชนะ" },
];

export const PROMOTION_OPTIONS = [
  { typeCode: "ALLDAY", typeName: "ฝากทั้งวัน" },
  { typeCode: "NEWUSER", typeName: "สมาชิกใหม่" },
  { typeCode: "FIRSTTIME", typeName: "ฝากแรกของวัน" },
  { typeCode: "GOLDTIME", typeName: "นาทีทอง" },
  { typeCode: "7DAYINROW", typeName: "ฝากติดต่อกัน 7 วัน" },
];

export const GAME_OPTIONS = [
  { typeCode: "ALL", typeName: "ทั้งหมด" },
  { typeCode: "SPORT", typeName: "กีฬา" },
  { typeCode: "SLOT", typeName: "สล๊อต" },
  { typeCode: "LIVE", typeName: "คาสิโน" },
  { typeCode: "CARD", typeName: "เกมส์สกิล" },
  // { typeCode: "TABLE_GAME", typeName: "สกิลเกมส์" },
  // { typeCode: "FISHING", typeName: "ยิงปลา" },
  // {typeCode: "LOTTO", typeName: "LOTTO"},
  // {typeCode: "MULTI_PLAYER", typeName: "MULTI_PLAYER"},
  // {typeCode: "POKER", typeName: "POKER"},
  // {typeCode: "KENO", typeName: "KENO"},
  // {typeCode: "TRADING", typeName: "TRADING"}
];

export const TURNOVER_OPTIONS = [
  { typeCode: "TURNOVER", typeName: "เทิร์นโอเวอร์" },
  { typeCode: "MINCREDIT", typeName: "ถอนขั้นต่ำ" },
];

export const FOOTER_ICON_TYPE_OPTIONS = [
  {
    val: "1",
    text: "Contact US",
  },
  {
    val: "2",
    text: "PAYMENT METHOD",
  },
  {
    val: "4",
    text: "DEPOSIT METHOD",
  },
  {
    val: "3",
    text: "PROVIDER",
  },
];

export const REFUND_TYPE_OPTIONS = [
  { typeCode: "WEEKLY", typeName: "รายอาทิตย์" },
  { typeCode: "MONTHLY", typeName: "รายเดือน" },
];

export const DEPOSIT_TRANSACTION_SEARCH_OPTIONS = [
  {
    label: "all",
    value: "",
  },
  {
    label: "success",
    value: "SUCCESS",
  },
  {
    label: "inprogress",
    value: "PENDING",
  },
  {
    label: "error",
    value: "ERROR",
  },
  {
    label: "reject",
    value: "REJECT",
  },
  // {
  //   label: "rejectAndRefund",
  //   value: "REJECT_N_REFUND",
  // },
  {
    label: "blockAuto",
    value: "BLOCK_AUTO",
  },
  {
    label: "notSure",
    value: "NOT_SURE",
  },
];

export const WITHDRAW_TRANSACTION_SEARCH_OPTIONS = [
  {
    label: "all",
    value: "",
  },
  {
    label: "success",
    value: "SUCCESS",
  },
  {
    label: "inprogress",
    value: "PENDING",
  },
  {
    label: "error",
    value: "ERROR",
  },
  {
    label: "reject",
    value: "REJECT",
  },
  {
    label: "rejectAndRefund",
    value: "REJECT_N_REFUND",
  },
  {
    label: "blockAuto",
    value: "BLOCK_AUTO",
  },
];

export const UPDATE_WITHDRAW_STATUS_OPTIONS = [
  { value: "SUCCESS", label: "success" },
  { value: "PENDING", label: "pending" },
  { value: "ERROR", label: "error" },
  { value: "REJECT", label: "reject" },
  { value: "REJECT_N_REFUND", label: "rejectAndRefund" },
];

export const LEVEL_VALIDITY_OPTIONS = [
  { val: "", text: "-" },
  { val: 1, text: "1 Month" },
  { val: 3, text: "3 Month" },
  { val: 6, text: "6 Month" },
  { val: 12, text: "1 Year" },
  { val: 24, text: "2 Year" },
];

export const STAFF_CREDIT_HISTORY_SEARCH_OPTIONS = [
  {
    label: "ทั้งหมด",
    value: "ALL",
  },
  {
    label: "ลบเครดิต",
    value: "CUT_CREDIT",
  },
  {
    label: "สลิปไม่แสดง",
    value: "NO_SLIP",
  },
  {
    label: "เพิ่มเครดิต",
    value: "ADD_CREDIT",
  },
];
