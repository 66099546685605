export const labelStyles = {
  components: {
    FormLabel: {
      baseStyle: {
        color: "#8C9592",
        fontSize: "16px",
        marginBottom: "0px",
      },
    },
  },
};
