import { useQuery } from "react-query";
import {
  fetchDashboardMarketingSummary,
  fetchDashboardSummary,
} from "services/dashboard/dashboardService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE, MARKETING_ROLE } from "variables/system";

import { useErrorHandler } from "../../common/useErrorHandler";

export const useDashboardSummary = (startDate, endDate, role, mid = null) => {
  const handleError = useErrorHandler();
  const fetchSummary = async () => {
    const req = { startDate: startDate, endDate: endDate, mid: mid };
    return role !== MARKETING_ROLE
      ? await fetchDashboardSummary(req)
      : await fetchDashboardMarketingSummary(req);
  };

  const { data: summary, isLoading: isSummaryLoading } = useQuery(
    [QueryKeys.DASHBOARD_SUMMARY, startDate, endDate, role],
    fetchSummary,
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
      select: (res) => res.data.data,
    },
  );

  return { summary, isSummaryLoading };
};
